/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { Input, Form, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonComponent from '../../components/button/Button';
import InsideHeader from '../../components/inside-header/InsideHeader';
import CustomInput from '../../components/input/CustomInput';
import { ADD_USER, EDIT_USER, GET_USER_BY_ID } from './api';

const AddEditUser = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id = null } = useParams();
  const operationName = id ? 'edit' : 'add';

  const addEditUser = async (value: any) => {
    let res;
    try {
      if (operationName === 'edit') {
        res = await EDIT_USER(id, value);
      }
      if (operationName === 'add') {
        res = await ADD_USER(value);
      }
      if (res?.status === 1 || res?.status === '1') {
        message.success(res?.message);
        navigate('/users');
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const getEditData = async () => {
    try {
      const res = await GET_USER_BY_ID(id);
      if (res?.status === 1) {
        form.setFieldsValue({
          ...res?.data,
        });
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  useEffect(() => {
    if (operationName === 'edit') {
      getEditData();
    }
  }, [form, id, operationName]);

  return (
    <InsideHeader
      className="add-edit-form box-shadow-2"
      style={{ width: '700px' }}
    >
      <h1 className="d-flex justify-content-center font-weight-600 font-size-24 line-height-34">
        {operationName === 'add' ? ' Create New User' : 'Edit User'}
      </h1>
      <Form form={form} name="control-hooks" onFinish={addEditUser}>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please fill First Name' }]}
        >
          <CustomInput size="large" placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please fill Last Name' }]}
        >
          <CustomInput size="large" placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please fill Email Id' },

            {
              message: 'Please input Valid Email!',
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line
                if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject();
              },
            },
          ]}
        >
          <CustomInput size="large" placeholder="xyz@gmail.com" />
        </Form.Item>

        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: 'Please fill Username' }]}
        >
          <CustomInput size="large" placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          extra={
            operationName === 'edit'
              ? 'Leave blank to keep the same password'
              : ''
          }
          rules={[
            {
              required: operationName === 'add' && true,
              message: 'Please fill Password',
            },
          ]}
        >
          <Input.Password
            className="input-control border-radius-8"
            placeholder="xyz@123"
            allowClear
          />
        </Form.Item>

        <div className="form-btn-group">
          <ButtonComponent
            className="mr-2 width-150 btn-cancel btn-component"
            style={{ background: 'transparant', color: '#8C8673' }}
            onClick={() => navigate('/users')}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            htmlType="submit"
            type="primary"
            className="btn-save btn-all btn-component"
          >
            {operationName === 'add' ? 'Create' : 'Edit'}
          </ButtonComponent>
        </div>
      </Form>
    </InsideHeader>
  );
};

export default AddEditUser;
