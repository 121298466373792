// External
import axios from 'axios';

// Internal
import { UNAUTHORIZED_REQUEST } from 'utils/constants';

// Variable
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.interceptors.request.use((config) => {
  if (config.headers) {
    const accessToken = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${accessToken} `;
    config.headers['Content-Type'] = 'application/json';
    return config;
  }
  return config;
});

export const methods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

// Function
export const service = async ({
  url = '',
  data = {},
  method = '',
  params = {},
}) => {
  try {
    const res = await axios({
      method,
      url,
      data,
      params,
    });
    return res?.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (
        error?.response.status === UNAUTHORIZED_REQUEST &&
        url !== '/authentication-user'
      ) {
        localStorage.clear();
        window.location.reload();
        return '';
      }
      throw error?.response?.data?.message;
    }
  }
};
