// Build-In
import React from 'react';

// External
import { Route } from 'react-router-dom';

// Internal
import { router } from 'utils/constants';
import AddEditUser from '../AddEditUser';
import Users from '../Users';

const AppRoutes = () => {
  return (
    <>
      <Route path={router.user} element={<Users />} />
      <Route path={router.userAdd} element={<AddEditUser />} />
      <Route path={router.userEdit()} element={<AddEditUser />} />
    </>
  );
};

export default AppRoutes;
