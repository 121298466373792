import React from 'react';

import Logo from '../../assets/images/logo.png';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const TableImage = ({ image }: any) => {
  return (
    <div>
      {image ? (
        <img
          src={image}
          loading="lazy"
          crossOrigin="anonymous"
          className="table-cover-img"
          alt=""
        />
      ) : (
        <img src={Logo} loading="lazy" className="table-cover-img" alt="" />
      )}
    </div>
  );
};

export default TableImage;
