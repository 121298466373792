import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, message, Row } from 'antd';

import { useAuth } from 'context/AuthContext';
import { UPDATE_ADMIN_USER } from './api';
import { AdminUser } from './types';
import InsideHeader from 'components/inside-header/InsideHeader';

const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);

  const { userData, setUserData } = useAuth();

  const [form] = Form.useForm();

  const onFinish = async (value: AdminUser) => {
    try {
      const res = await UPDATE_ADMIN_USER(userData?.id, value);
      if (res.status === 1 || res.status === '1') {
        const user = res.data;
        message.success(res.message);
        localStorage.setItem('user-data', JSON.stringify(user));
        setUserData(user);
        setIsEdit(false);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEdit(false);
    form.setFieldsValue(userData);
  };

  useEffect(() => {
    form.setFieldsValue(userData);
  }, [form]);

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
          md: { span: 8 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 16 },
          lg: { span: 16 },
        }}
        labelAlign="left"
        className="form-style"
      >
        <Row justify="center" gutter={0} className="mt-4">
          <Col xs={24} sm={24} md={20} lg={15} xl={15} className="gutter-row">
            <InsideHeader>
              <div className="d-flex justify-content-between">
                <h1 className="font-size-28">Profile</h1>
                {isEdit === false && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => setIsEdit(true)}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: 'First Name is required' }]}
              >
                <Input
                  size="large"
                  className="input-control"
                  placeholder="First Name"
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[{ required: true, message: 'Last Name is required' }]}
              >
                <Input
                  className="input-control"
                  size="large"
                  placeholder="Last Name"
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Email is required' }]}
              >
                <Input
                  className="input-control"
                  size="large"
                  placeholder="xyz@gmail.com"
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              {isEdit && (
                <div className="form-btn-group">
                  <Button
                    size="large"
                    className="mr-2 width-180"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="width-180"
                  >
                    Update
                  </Button>
                </div>
              )}
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Profile;
