/* eslint-disable @typescript-eslint/no-explicit-any */
import { methods, service } from 'service/service';

export const GET_WORKOUTS = (
  limit: number,
  skip: number,
  fitnessLevel?: string | null,
  trainingZoneId?: string | null,
  equipmentId?: string | null,
  search?: string | null,
  sort?: any,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (fitnessLevel) {
    query.fitness_level = fitnessLevel;
  }
  if (trainingZoneId) {
    query.trainingZoneId = trainingZoneId;
  }
  if (equipmentId) {
    query.equipmentId = equipmentId;
  }
  if (sort) {
    const name = Object.keys(sort)[0];
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: '/workout', method: methods.GET, params: query });
};

const GET_PROGRAM = (
  limit: number,
  skip: number,
  search?: string | null,
  sort?: any,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };

  if (search) {
    query.search = search;
  }

  if (sort) {
    const name = Object.keys(sort)[0];
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: '/program', method: methods.GET, params: query });
};

const GET_PROGRAM_BY_ID = (id: string | null) =>
  service({ url: `program/${id}`, method: methods.GET });

const DELETE_PROGRAM = (id: string | undefined) =>
  service({ url: `/program/${id}`, method: methods.DELETE });

const MULTIPLE_DELETE_PROGRAM = (payload: any) => {
  return service({ url: '/program', method: methods.DELETE, data: payload });
};

const ADD_PROGRAM = (payload: any) =>
  service({ url: '/program', method: methods.POST, data: payload });

const EDIT_PROGRAM = (id: string | null, payload: any) =>
  service({ url: `program/${id}`, method: methods.PATCH, data: payload });

export const PROGRAM = {
  GET: GET_PROGRAM,
  DELETE: DELETE_PROGRAM,
  MULTIDELETE: MULTIPLE_DELETE_PROGRAM,
  CREATE: ADD_PROGRAM,
  EDIT: EDIT_PROGRAM,
  GET_BY_ID: GET_PROGRAM_BY_ID,
};
