/* eslint-disable @typescript-eslint/no-explicit-any */
// Build-In
import { useEffect, useState } from 'react';

// External
import { Form, message, Upload, UploadProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import { useAuth } from 'context/AuthContext';
import { router } from 'utils/constants';
import { EXERCISE } from '../api';
import { GET_SECTION } from 'features/workout/api';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import axios from 'axios';

const useAddEditHook = () => {
  //  useState
  const [fileList, setFileList] = useState<any>([]);

  const [fileListImage, setFileImage] = useState<any>([]);
  const [imageId, setImageId] = useState<string | null>(null);

  const [sectionName, setSectionName] = useState<any>();
  const [trainingZone, setTrainingZone] = useState<any>();
  const [equipment, setEquipment] = useState<any>();
  const [videoId, setVideoId] = useState<string | null>(null);

  // Variable
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { token } = useAuth();
  const { id = null } = useParams();
  const operationName = id ? 'edit' : 'add';
  let currentChunkIndex: number | null = null;

  const uploadBtnProps: UploadProps = {
    multiple: false,
    fileList,
    beforeUpload(file: any) {
      if (fileList.length > 1 || fileList.length === 1) {
        message.warn('File Already Uploaded! Please delete that.');
        return false;
      }
      const type = file.type === 'video/mp4';
      if (!type) {
        message.error('Only Video.mp4 file are allowed!');
        return false;
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const type = info.file.type === 'video/mp4';
      if (type) {
        if (info.fileList.length === 1) {
          setFileList([...info.fileList]);
        }
        if (info.file.status === 'done') {
          setVideoId(info.file.response?.mediaItem?.id);
        }
        if (info.file.status === 'error') {
          setFileList([]);
          form.setFieldsValue({ videoId: null });
          message.error(
            `${info?.file?.response?.message || 'Something went wrong !'}`,
          );
        }
      } else {
        setFileList([]);
        form.setFieldsValue({ videoId: null });
      }
    },
    async onRemove() {
      form.setFieldsValue({ videoId: null });
      setFileList([]);
    },
    async customRequest({ file, onProgress, onSuccess, onError }) {
      currentChunkIndex = 0;
      recursiveFunction(file, onProgress, onSuccess, onError);
    },
  };

  const recursiveFunction = (
    file: any,
    onProgress: any,
    onSuccess: any,
    onError: any,
  ) => {
    if (
      currentChunkIndex !== null &&
      currentChunkIndex >= 0 &&
      form.getFieldsValue().videoId.fileList.length > 0
    ) {
      const reader = new FileReader();
      const chunkSize = 10 * 1024 * 1024;
      const from = currentChunkIndex * chunkSize;
      const to = from + chunkSize;
      const blob = file.slice(from, to);
      const percent = Math.round(
        ((chunkSize * (currentChunkIndex + 1)) / file.size) * 100,
      ).toFixed(2);
      onProgress({ percent: percent }, file);
      reader.onload = (e) => {
        const data = e.target?.result;
        const params = new URLSearchParams();
        params.set('name', file.name);
        params.set('size', file.size);
        params.set('currentChunkIndex', `${currentChunkIndex}`);
        params.set('totalChunks', `${Math.ceil(file.size / chunkSize)}`);
        const url =
          `${process.env.REACT_APP_API_ENDPOINT}/upload-video?` +
          params.toString();
        axios
          .post(url, { data })
          .then((response: any) => {
            const chunks = Math.ceil(file.size / chunkSize) - 1;
            const isLastChunk = currentChunkIndex === chunks;
            if (isLastChunk) {
              currentChunkIndex = null;
              onSuccess(response.data.data, file);
            } else {
              if (currentChunkIndex !== null) {
                currentChunkIndex += 1;
                recursiveFunction(file, onProgress, onSuccess, onError);
              }
            }
          })
          .catch((error) => onError(error));
      };
      reader.readAsDataURL(blob);
    }
  };

  const uploadBtnPropsImage: UploadProps = {
    multiple: false,
    fileList: fileListImage,
    action: `${process.env.REACT_APP_API_ENDPOINT}/upload-image`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload(file) {
      if (fileListImage.length > 1 || fileListImage.length === 1) {
        message.warn('File Already Uploaded! Please delete that.');
        return false;
      }
      const type =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg';
      if (!type) {
        message.error('Only .jpeg, .png, .jpg files are allowed!');
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileImage([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setImageId(info.file.response?.data?.mediaItem?.id);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove() {
      setFileImage([]);
      setImageId(null);
    },
  };

  const onFinish = async (formValue: any) => {
    try {
      let res;
      const payload = {
        ...formValue,
        videoId,
        exerciseThumbnailId: imageId,
      };
      if (operationName === 'edit') {
        res = await EXERCISE.EDIT_EXERCISE(id, payload);
      }
      if (operationName === 'add') {
        res = await EXERCISE.ADD_EXERCISE(payload);
      }
      if (res?.status === 1 || res?.status === '1') {
        message.success(res?.message);
        navigate(router.exercise);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchSection = async () => {
    setSectionName([]);
    try {
      const res = await GET_SECTION();
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((section: any) => {
          return {
            label: section?.name,
            value: section?.id,
          };
        });
        setSectionName(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  useEffect(() => {
    const getEditData = async () => {
      try {
        const res = await EXERCISE.GET_EXERCISE_BY_ID(id);
        const imagePath: any = res?.data?.thumbnail?.media;
        const videoPath: any = res?.data?.video?.media;
        const optionSelectedEquipments = res?.data?.exercise_equipments
          ?.map((item: any) => item?.equipment?.id)
          .filter((item: string) => item);

        const optionSelectedTrainingZones = res?.data?.exercise_training_zones
          ?.map((item: any) => item?.training_zone?.id)
          .filter((item: string) => item);

        const optionSelectedSection = res?.data?.section_items
          ?.map((item: any) => item?.section?.id)
          .filter((item: string) => item);

        if (res?.status === 1) {
          form.setFieldsValue({
            ...res?.data,
            equipment: optionSelectedEquipments,
            training_zones: optionSelectedTrainingZones,
            sectionId: optionSelectedSection,
          });

          setVideoId(res?.data?.videoId);

          if (imagePath) {
            setFileImage([
              {
                uid: '1',
                name: 'image',
                status: 'done',
                url: imagePath,
                thumbUrl: imagePath,
              },
            ]);
            setImageId(res?.data?.exerciseThumbnailId);
          }
          if (videoPath) {
            setFileList([
              {
                uid: '1',
                name: 'video',
                status: 'done',
                url: videoPath,
                thumbUrl: videoPath,
              },
            ]);
          }
        }
      } catch (error) {
        message.error(`${error || 'Something went wrong !'}`);
      }
    };
    if (operationName === 'edit') {
      getEditData();
    }
  }, [form, id, operationName]);

  useEffect(() => {
    fetchSection();
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  return {
    form,
    onFinish,
    uploadBtnProps,
    uploadBtnPropsImage,
    trainingZone,
    equipment,
    sectionName,
    navigate,
    operationName,
  };
};

export default useAddEditHook;
