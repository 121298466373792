/* eslint-disable @typescript-eslint/no-explicit-any */
// Built In
import React, { useEffect, useState } from 'react';

// External
import { message } from 'antd';
import { ColumnsType } from 'antd/lib/table';

// Internal
import ButtonComponent from 'components/button/Button';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import { IDropDown, IResponse } from 'types';
import { GET_WORKOUTS } from '../api';
import { IWorkoutDetails } from '../types';
import TableImage from 'components/table-image/TableImage';

const useStepOneHook = ({ programBuilder, setProgramBuilder }: any) => {
  // useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workoutsData, setWorkoutsData] =
    useState<IResponse<IWorkoutDetails>>();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string | null>(null);
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [tempSearch, setTempSearch] = useState<string | null>(null);
  const [weekSliderVisible, setWeekSliderVisible] = useState<boolean>(false);
  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);
  const [trainingZoneValue, setTrainingZoneValue] = useState<string | null>(
    null,
  );
  const [equipmentValue, setEquipmentValue] = useState<string | null>(null);
  const [fitnessLevelValue, setFitnessLevelValue] = useState<string | null>(
    null,
  );
  const [mainIndex, setMainIndex] = useState<number | null>(null);
  const [editData, setEditData] = useState<any>();
  const [programName, setProgramName] = useState<any>();

  const [isAdd, setIsAdd] = useState(false);

  const columns: ColumnsType<IWorkoutDetails> = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 130,
      render: (thumbnail) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Workout Name',
      dataIndex: 'workout_name',
      key: 'workoutName',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: false,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: any, record) => {
        return (
          <div className="g-3">
            <ButtonComponent
              className="border-primary"
              style={{ padding: '12px 16px' }}
              onClick={() => {
                const temp = [...programBuilder];
                const length = programBuilder.length;
                const obj = {
                  position_no: 5,
                  workout: {
                    id: record?.id,
                    cost_type: record?.cost_type,
                    description: record?.description,
                    duration: record?.duration,
                    thumbnail: {
                      id: record?.thumbnail?.id,
                      media: record?.thumbnail?.media,
                      path: record?.thumbnail?.path,
                      source: record?.thumbnail?.source,
                    },
                    fitness_level: record?.fitness_level,
                    instruction: record?.instruction,
                    workout_cost: record?.workout_cost,
                    workout_name: record?.workout_name,
                    workout_visibility: record?.workout_visibility,
                  },
                };
                if (mainIndex != null) {
                  obj.position_no =
                    temp[mainIndex].program_week_workouts.length + 1;
                  temp[mainIndex].program_week_workouts.push(obj);
                } else if (programBuilder?.length >= 1) {
                  obj.position_no =
                    temp[programBuilder?.length - 1].program_week_workouts
                      .length + 1;
                  temp[programBuilder?.length - 1].week_name = programName;
                  temp[programBuilder?.length - 1].program_week_workouts.push(
                    obj,
                  );
                } else {
                  const index = programBuilder.findIndex(
                    (item: any) => item?.week_no === length,
                  );
                  obj.position_no =
                    temp[index].program_week_workouts.length + 1;
                  temp[index].week_name = programName;
                  temp[index].program_week_workouts.push(obj);
                }

                setProgramBuilder(temp);
                message.success('Workout Added Successfully');
              }}
            >
              + Add
            </ButtonComponent>
          </div>
        );
      },
    },
  ];

  // Function
  const handleChangeOfSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleChangeOfName = (e: any) => {
    setProgramName(e.target.value);
    const temp = [...programBuilder];
    if (isAdd) {
      temp[programBuilder.length - 1].week_name = e.target.value;
    } else {
      if (mainIndex !== null) {
        temp[mainIndex].week_name = e.target.value;
      }
    }
    setProgramBuilder(temp);
  };

  const handleSearch = () => {
    setPage(1);
    setSearch(tempSearch);
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setPage(pageT);
    setPageSize(pageSizeT);
  };

  const fetchWorkouts = async (
    pageP: number,
    pageSizeP: number,
    fitness: string | null,
    trainingZoneId: string | null,
    equipmentId: string | null,
    search: string | null,
  ) => {
    setIsLoading(true);
    setWorkoutsData(undefined);
    try {
      const res = await GET_WORKOUTS(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        fitness,
        trainingZoneId,
        equipmentId,
        search,
      );
      if (res?.status === 1 || res?.status === '1') {
        setWorkoutsData(res);
      }
    } catch (err) {
      setIsLoading(false);
      message.error(`${err || 'Something went wrong!'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const clearAllFilter = () => {
    setFitnessLevelValue(null);
    setTrainingZoneValue(null);
    setEquipmentValue(null);
  };

  useEffect(() => {
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  // useEffect
  useEffect(() => {
    fetchWorkouts(
      page,
      pageSize,
      fitnessLevelValue,
      trainingZoneValue,
      equipmentValue,
      search,
    );
  }, [
    page,
    equipmentValue,
    fitnessLevelValue,
    pageSize,
    search,
    trainingZoneValue,
  ]);

  return {
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setFitnessLevelValue,
    fitnessLevelValue,
    weekSliderVisible,
    setWeekSliderVisible,
    clearAllFilter,
    handleChangeOfSearch,
    handleSearch,
    tempSearch,
    setIsShowFilter,
    isShowFilter,
    isLoading,
    workoutsData,
    columns,
    onChangePage,
    page,
    setSearch,
    mainIndex,
    setMainIndex,
    editData,
    setEditData,
    handleChangeOfName,
    programName,
    setProgramName,
    isAdd,
    setIsAdd,
  };
};

export default useStepOneHook;
