// Build-In
import React from 'react';

// External
import { Route } from 'react-router-dom';

// Internal
import ExerciseAddEdit from '../components/ExerciseAddEdit';
import { router } from 'utils/constants';
import Exercise from '../Exercise';

const AppRoutes = () => {
  return (
    <>
      <Route path={router.exercise} element={<Exercise />} />
      <Route path={router.exerciseAdd} element={<ExerciseAddEdit />} />
      <Route path={router.exerciseEdit()} element={<ExerciseAddEdit />} />
    </>
  );
};

export default AppRoutes;
