import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, message, MenuProps, Menu } from 'antd';

import { useAuth } from '../../context/AuthContext';
import { UserIcon } from '../../icon/CommonIcon';
import logo from '../../assets/images/logo.png';
import LogOutIcon from '../../icon/LogOutIcon';
import { router } from '../../utils/constants';
import CustomModel from '../model/CustomModel';
import PersonIcon from 'icon/PersonIcon';
import { LOGOUT } from 'service/api';
import KeyIcon from 'icon/KeyIcon';

const Header = () => {
  // useState
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const { setToken, setUserData } = useAuth();

  // Variable
  const location = useLocation();
  const navigate = useNavigate();

  // Function
  const handleOk = async () => {
    try {
      const res = await LOGOUT();
      if (res?.status === 1 || res?.status === '1') {
        setToken(null);
        setUserData(null);
        localStorage.clear();
        navigate(router.login);
      }
    } catch (error) {
      message.error('SomeThing Went Wrong');
    }
  };

  const matchPathnameWithKey = () => {
    const splitPathname = location.pathname.split('/');
    const actualPathname = splitPathname[1];
    if (actualPathname === '') {
      return ['dashboard'];
    }
    const returnVal = [actualPathname];
    return returnVal;
  };

  const handleCancel = () => {
    setIsShowPopUp(false);
  };

  const items: MenuProps['items'] = [
    {
      key: router.profile,
      label: (
        <div
          className="d-flex align-center px-1"
          onClick={() => navigate(router.profile)}
        >
          <PersonIcon isActive={matchPathnameWithKey()[0] === router.profile} />
          <span className="ml-2">Profile</span>
        </div>
      ),
    },
    {
      key: router.changePassword,
      label: (
        <div
          className="d-flex align-center px-1"
          onClick={() => navigate(router.changePassword)}
        >
          <KeyIcon
            isActive={matchPathnameWithKey()[0] === router.changePassword}
          />
          <span className="ml-2">Change Password</span>
        </div>
      ),
    },
    {
      key: 0,
      label: (
        <div
          className="d-flex align-center px-1"
          onClick={() => setIsShowPopUp(true)}
        >
          <LogOutIcon />
          <span className="ml-2">Logout</span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ position: 'sticky', top: '0', zIndex: '1000' }}>
      {location.pathname === '/login' ? (
        <div className="header d-flex align-items-center justify-content-center w-100 h-80">
          <img src={logo} alt="logo" />
        </div>
      ) : (
        <>
          <div className="header d-flex align-items-center justify-content-between px-2 h-80">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" />
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center g-50 ">
                <Dropdown
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                  overlay={
                    <Menu>
                      {/* eslint-disable-next-line */}
                      {items.map((item: any) => (
                        <Menu.Item key={item.key}>{item.label}</Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <div>
                    <div className="user-div headerProfileUser border-radius-50 p-8 bg-grey cursor-pointer">
                      <UserIcon />
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          {isShowPopUp && (
            <CustomModel
              open={isShowPopUp}
              handleOk={handleOk}
              handleCancel={handleCancel}
              description="Are you sure you want to logout ?"
              title="Logout"
              okBtnText="Logout"
            />
          )}
        </>
      )}
    </div>
  );
};

export default Header;
