// Built-In
import React from 'react';

// External
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';

// Internal
import {
  ExerciseIcon,
  WorkoutsIcon,
  SectionsIcon,
  UserIcon,
  ProgramIcon,
} from '../../icon/SidebarIcon';
import { router } from 'utils/constants';

// Interface
interface IProps {
  setIsShowSidebar: (visible: boolean) => void;
}

const { Sider } = Layout;

const SideMenu = ({ setIsShowSidebar }: IProps) => {
  // Variable
  const location = useLocation();
  const menuList = [
    {
      key: 'exercise',
      activeIcon: <ExerciseIcon isActive={true} />,
      inActiveIcon: <ExerciseIcon isActive={false} />,
      name: 'Exercise',
      url: router.exercise,
    },
    {
      key: 'workout',
      activeIcon: <WorkoutsIcon isActive={true} />,
      inActiveIcon: <WorkoutsIcon isActive={false} />,
      name: 'Workouts',
      url: router.workout,
    },
    {
      key: 'program',
      activeIcon: <ProgramIcon isActive={true} />,
      inActiveIcon: <ProgramIcon isActive={false} />,
      name: 'Programs',
      url: router.program,
    },
    {
      key: 'sections',
      activeIcon: <SectionsIcon isActive={true} />,
      inActiveIcon: <SectionsIcon isActive={false} />,
      name: 'Sections',
      url: '/sections',
    },
    {
      key: 'users',
      activeIcon: <UserIcon isActive={true} />,
      inActiveIcon: <UserIcon isActive={false} />,
      name: 'Users',
      url: '/users',
    },
  ];

  // Function
  const matchPathnameWithKey = () => {
    const splitPathname = location.pathname.split('/');
    const actualPathname = splitPathname[1];
    if (actualPathname === '') {
      return ['dashboard'];
    }
    const returnVal = [actualPathname];
    return returnVal;
  };

  return (
    <Sider
      collapsible
      onCollapse={(collapsed) => {
        setIsShowSidebar(collapsed);
      }}
      reverseArrow={true}
      breakpoint="md"
      theme="light"
      width={190}
      style={{
        overflow: 'auto',
        position: 'fixed',
        left: 0,
        top: '76px',
        bottom: '0',
        backgroundColor: '#ffffff',
      }}
      className="box-shadow-layout"
    >
      <Menu theme="light" mode="vertical" selectedKeys={matchPathnameWithKey()}>
        {menuList?.map((item) => {
          const isActive = item.key === matchPathnameWithKey()[0];
          return (
            <Menu.Item key={item.key} className="sidebar-menu">
              {isActive ? item.activeIcon : item.inActiveIcon}
              <span
                className={isActive ? 'font-weight-600 font-size-16' : 'title'}
              >
                {item.name}
              </span>
              <Link to={`${item.url}`} />
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
