// Build-In
import React from 'react';

// External
import { Col, Input, Radio, Row, Select, Table, Tooltip } from 'antd';

// Internal
import { FilterIcon, SearchIcon } from '../../icon/CommonIcon';
import InsideHeader from '../../components/inside-header/InsideHeader';
import ButtonComponent from '../../components/button/Button';
import CustomModel from '../../components/model/CustomModel';
import useExerciseHook from './hook/useExerciseHook';
import InfoDrawer from './components/InfoDrawer';
import { router } from 'utils/constants';
import PaginationCom from 'components/pagination/Pagination';

const Exercise = () => {
  const {
    isShowFilter,
    navigate,
    setIsShowFilter,
    infoSliderVisible,
    setInfoSliderVisible,
    handleCancelDeleteModal,
    deleteExercise,
    isDeleteModalVisible,
    infoContent,
    handleCancelMultiDelete,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setCategoryValue,
    categoryValue,
    section,
    setSectionValue,
    sectionValue,
    setStatusValue,
    statusValue,
    clearAllFilter,
    selectRow,
    handleMultipleDelete,
    setIsMultiDeleteVisible,
    isMultiDeleteVisible,
    rowSelection,
    columns,
    exerciseData,
    tableLoading,
    onChange,
    onChangePage,
    currentPage,
    category,
    handleChangeOfSearch,
    handleKeyPressSearch,
  } = useExerciseHook();

  return (
    <>
      {/* Header */}
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search exercise by names..."
          className="input-control"
          type="search"
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon style={{ color: '#8f9bb3' }} />
            </Tooltip>
          }
          onKeyPress={handleKeyPressSearch}
          onChange={handleChangeOfSearch}
          allowClear
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            className="btn-inside-header mr-1 mb-1 border-radius-8 text-black btn-component d-flex align-items-center justify-content-center gap-10"
            onClick={() => setIsShowFilter(!isShowFilter)}
          >
            <FilterIcon style={{ color: '#2B2923' }} /> Exercise Filter
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            className="mr-1 width-180"
            onClick={() => navigate(router.exerciseAdd)}
          >
            + Create Exercise
          </ButtonComponent>
        </div>
      </InsideHeader>

      {/* Filter */}
      {isShowFilter && (
        <InsideHeader className="mt-3 px-24-30">
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Category"
                className="font-size-18 line-height-26 w-100"
                options={category}
                onChange={(e) => setCategoryValue(e)}
                value={categoryValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Section"
                className="font-size-18 line-height-26  w-100"
                options={section}
                onChange={(e) => setSectionValue(e)}
                value={sectionValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center g-3 gutter-row mb-1"
            >
              <div className="w-100">
                <span className="font-size-18 mb-0 line-height-26 mr-1">
                  Status
                </span>
                <Radio.Group
                  onChange={(e) => setStatusValue(e.target.value)}
                  value={statusValue}
                >
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">InActive</Radio>
                </Radio.Group>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              {/* eslint-disable-next-line */}
              <p
                onClick={clearAllFilter}
                className="text-primary font-size-18 mb-0 line-height-26 cursor-pointer"
              >
                Clear All
              </p>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {/* Multi Delete button */}
      {selectRow.length > 0 && (
        <div className="mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className="mb-0 font-weight-600">
                {selectRow.length} - Selected Exercise
              </h2>
            </div>
            <div>
              <ButtonComponent
                type="primary"
                onClick={() => setIsMultiDeleteVisible(true)}
              >
                Delete Selected Exercise
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}

      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        {/* Data Table */}
        <Table
          rowSelection={{
            type: 'checkbox',
            columnWidth: 40,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={exerciseData?.data}
          pagination={false}
          loading={tableLoading}
          rowKey="id"
          onChange={onChange}
          scroll={{ x: 900 }}
          className="w-100 overflow-auto"
        />

        {/* Pagination */}
        <div className="bg-white w-100 p-2 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {exerciseData?.data?.length} of {exerciseData?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={currentPage}
            showLessItems={true}
            total={exerciseData?.total}
          />
        </div>
      </div>

      {/* Info Drawer */}
      <InfoDrawer
        infoSliderVisible={infoSliderVisible}
        setInfoSliderVisible={setInfoSliderVisible}
        data={infoContent}
      />

      {/* Delete Modal */}
      <CustomModel
        open={isDeleteModalVisible}
        handleOk={deleteExercise}
        handleCancel={handleCancelDeleteModal}
        title="Delete"
        description="Are you sure you want to delete this?"
        okBtnText="Delete"
      />

      {/* Multi Delete Model  */}
      {isMultiDeleteVisible && (
        <CustomModel
          open={isMultiDeleteVisible}
          handleOk={handleMultipleDelete}
          handleCancel={handleCancelMultiDelete}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}
    </>
  );
};

export default Exercise;
