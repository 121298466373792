// Build-In
import React from 'react';

// External
import { Button, Form, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';

// Internal
import InsideHeader from 'components/inside-header/InsideHeader';
import ButtonComponent from 'components/button/Button';
import CustomInput from 'components/input/CustomInput';
import { router } from 'utils/constants';
import FileIcon from '../icon/FileIcon';
import IconUpload from '../icon/UploadIcon';
import useAddEditHook from '../hook/useAddEditHook';

// Variable
const { Option } = Select;

const ExerciseAddEdit = () => {
  const {
    form,
    onFinish,
    uploadBtnProps,
    uploadBtnPropsImage,
    trainingZone,
    equipment,
    sectionName,
    navigate,
    operationName,
  } = useAddEditHook();

  return (
    <InsideHeader className="add-edit-form box-shadow-2">
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        name="control-hooks"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name="exercise_name"
          label="Exercise Name"
          className="font-size-18 line-height-26"
          rules={[{ required: true, message: 'Please fill Exercise Name' }]}
        >
          <CustomInput size="large" placeholder="Exercise Name" />
        </Form.Item>

        <Form.Item
          name="video_duration"
          label="Duration"
          className="font-size-18 line-height-26"
        >
          <CustomInput type="number" size="large" placeholder="Duration" />
        </Form.Item>

        <Form.Item
          name="videoId"
          label="Upload Video"
          rules={[{ required: true, message: 'Please Upload Video' }]}
        >
          <Dragger
            className="border-radius-8"
            accept=".mp4"
            listType="picture"
            name="videoId"
            {...uploadBtnProps}
          >
            <p className="ant-upload-drag-icon">
              <FileIcon />
            </p>
            <p className="ant-upload-text">Drag and drop a file here or</p>
            <p
              className="ant-upload-hint border-radius-8 width-150 m-auto text-black-dark"
              style={{
                border: '1px solid #FBC90B',
                padding: '6px 0',
                margin: 'auto',
              }}
            >
              <IconUpload />
              Click To Upload
            </p>
          </Dragger>
        </Form.Item>

        <Form.Item label="Thumbnail Image">
          <Upload
            accept=".png, .jpg, .jpeg"
            listType="picture"
            name="image"
            className="thumb-img"
            {...uploadBtnPropsImage}
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              className="btn-thumb"
            >
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item name="training_zones" label="Training Zone:">
          <Select
            showSearch
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Select Training Zones"
            className="font-size-18 line-height-26"
            options={trainingZone}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item name="equipment" label="Equipment:">
          <Select
            showSearch
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Select Equipment"
            className="font-size-18 line-height-26"
            options={equipment}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item
          name="exercise_category"
          label="Exercise Category:"
          rules={[{ required: true, message: 'Please Select Category' }]}
        >
          <Select
            placeholder="Select Category"
            className="font-size-18 line-height-26"
          >
            <Option value="Beginner">Beginner</Option>
            <Option value="Intermediate">Intermediate</Option>
            <Option value="Advanced">Advance</Option>
            <Option value="Everyone">Everyone</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Exercise Description:"
          rules={[
            {
              required: true,
              message: 'Please fill Description',
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            className="border-radius-8 font-size-18 line-height-26"
            rows={3}
            style={{ padding: '12px 16px' }}
          />
        </Form.Item>

        <Form.Item name="sectionId" label="Section Name:">
          <Select
            mode="multiple"
            placeholder="Select Section"
            className="font-size-18 line-height-26"
            options={sectionName}
          />
        </Form.Item>

        {/* <Form.Item
          name="status"
          label="Status:"
          rules={[{ required: true, message: 'Please Select Status' }]}
          style={{ display: 'none' }}
        >
          <Radio.Group>
            <Radio value="active">Active</Radio>
            <Radio value="inactive">InActive</Radio>
          </Radio.Group>
        </Form.Item> */}

        <div className="form-btn-group d-flex justify-content-center">
          <ButtonComponent
            className="mr-2 width-150 btn-cancel btn-component"
            style={{ background: 'transparent', color: '#8C8673' }}
            onClick={() => navigate(router.exercise)}
          >
            Cancel
          </ButtonComponent>

          <ButtonComponent
            htmlType="submit"
            type="primary"
            className="btn-save btn-all btn-component width-150"
          >
            {operationName === 'add' ? 'Create' : 'Update'}
          </ButtonComponent>
        </div>
      </Form>
    </InsideHeader>
  );
};

export default ExerciseAddEdit;
