// Build-In
import React, { useEffect, useState } from 'react';

// External
import { TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { message, Tooltip } from 'antd';

// Internal
import { ColumnsType } from 'antd/lib/table';
import { router } from 'utils/constants';
import { TableRowSelection } from 'antd/lib/table/interface';
import { IDropDown, IResponse } from 'types';
import TableImage from '../../../components/table-image/TableImage';
import { IExercise } from '../types';
import { EXERCISE } from '../api';
import { DeleteIcon, EditIcon, InfoIcon } from 'icon/CommonIcon';
import { GET_SECTION } from 'features/workout/api';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';

// Variable
const category = [
  {
    id: 1,
    label: 'Beginner',
    value: 'Beginner',
  },
  {
    id: 2,
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    id: 3,
    label: 'Advance',
    value: 'Advanced',
  },
  {
    id: 4,
    label: 'Everyone',
    value: 'Everyone',
  },
];

const useExerciseHook = () => {
  // useState
  const [exerciseData, setExerciseData] = useState<IResponse<IExercise> | null>(
    null,
  );
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [equipmentValue, setEquipmentValue] = useState<string | null>(null);
  const [categoryValue, setCategoryValue] = useState<string | null>(null);
  const [sectionValue, setSectionValue] = useState<string | null>(null);
  const [statusValue, setStatusValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [tempSearch, setTempSearch] = useState<string | null>(null);
  const [trainingZoneValue, setTrainingZoneValue] = useState<string | null>(
    null,
  );

  const [isMultiDeleteVisible, setIsMultiDeleteVisible] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sort, setSort] = useState<any>(null);

  const [infoSliderVisible, setInfoSliderVisible] = useState<boolean>(false);
  const [infoContent, setInfoContent] = useState<IExercise>();

  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);
  const [section, setSection] = useState<IDropDown[]>([]);

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const [selectRow, setSelectRow] = useState<IExercise[]>([]);
  const [selectRowKey, setSelectRowKey] = useState<React.Key[]>([]);

  // Variable
  const navigate = useNavigate();

  // Function
  const fetchExercise = async (
    pageP: number,
    pageSizeP: number,
    search: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortP: any,
    status: string | null,
    trainingZoneId: string | null,
    equipmentId: string | null,
    exercise_category: string | null,
    sectionId: string | null,
  ) => {
    setTableLoading(true);
    setExerciseData(null);
    try {
      const res = await EXERCISE.GET_EXERCISE(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        search,
        sortP,
        status,
        trainingZoneId,
        equipmentId,
        exercise_category,
        sectionId,
      );
      if (res?.status === 1 || res?.status === '1') {
        setExerciseData(res);
      }
    } catch (error) {
      setTableLoading(false);
      message.error(`${error || 'Something went wrong!'}`);
    } finally {
      setTableLoading(false);
    }
  };

  const deleteExercise = async () => {
    try {
      const res = await EXERCISE.DELETE_EXERCISE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId('');
        setIsDeleteModalVisible(false);
        let page = currentPage;
        if (exerciseData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchExercise(
            page,
            pageSize,
            searchValue,
            sort,
            statusValue,
            trainingZoneValue,
            equipmentValue,
            categoryValue,
            sectionValue,
          );
        }
        message.success(res?.message);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  const handleCancelMultiDelete = () => {
    setIsMultiDeleteVisible(false);
    setSelectRow([]);
    setSelectRowKey([]);
  };

  const handleMultipleDelete = async () => {
    const payload = selectRow.map((item) => {
      return { id: item.id, deletedAt: new Date() };
    });
    try {
      const res = await EXERCISE.MULTIPLE_DELETE_EXERCISE(payload);
      if (res?.status === 1 || res?.status === '1') {
        handleCancelMultiDelete();
        let page = currentPage;
        if (exerciseData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchExercise(
            page,
            pageSize,
            searchValue,
            sort,
            statusValue,
            trainingZoneValue,
            equipmentValue,
            categoryValue,
            sectionValue,
          );
        }
        message.success('Record deleted successfully !');
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setDeleteId('');
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchSection = async () => {
    setSection([]);
    try {
      const res = await GET_SECTION();
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setSection(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setCurrentPage(pageT);
    setPageSize(pageSizeT);
  };

  const onChange = (
    pagination: TablePaginationConfig,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _filters: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const clearAllFilter = () => {
    setTrainingZoneValue(null);
    setEquipmentValue(null);
    setCategoryValue(null);
    setSectionValue(null);
    setStatusValue(null);
  };

  const handleChangeOfSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      setSearchValue(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      setCurrentPage(1);
      setSearchValue(tempSearch);
    }
  };

  useEffect(() => {
    fetchExercise(
      currentPage,
      pageSize,
      searchValue,
      sort,
      statusValue,
      trainingZoneValue,
      equipmentValue,
      categoryValue,
      sectionValue,
    );
  }, [
    currentPage,
    pageSize,
    searchValue,
    sort,
    statusValue,
    trainingZoneValue,
    equipmentValue,
    categoryValue,
    sectionValue,
  ]);

  useEffect(() => {
    fetchSection();
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  const rowSelection: TableRowSelection<IExercise> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setSelectRow(selectRowRecord);
      setSelectRowKey(selectedRowKeys);
    },
  };

  const columns: ColumnsType<IExercise> = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'media_item',
      align: 'center',
      width: 130,
      render: (thumbnail) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Name',
      dataIndex: 'exercise_name',
      key: 'name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Equipment',
      dataIndex: 'equipment',
      key: 'equipment',
      align: 'center',
      render: (_: number, record: IExercise) => {
        const equipments = record?.exercise_equipments
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((data: any) => data?.equipment?.name)
          .filter((equ) => equ);
        return equipments.join(', ') || '-';
      },
    },
    {
      title: 'Category',
      dataIndex: 'exercise_category',
      key: 'Category',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 150,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: number, record: IExercise) => {
        return (
          <div className="g-3">
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => navigate(router.exerciseEdit(record?.id))}
                className="font-size-20 edit-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>

            <Tooltip title="View Details">
              <InfoIcon
                className="font-size-20 info-btn-hover cursor-pointer mx-1"
                onClick={() => {
                  setInfoContent(record);
                  setInfoSliderVisible(true);
                }}
              />
            </Tooltip>

            <Tooltip title="Delete">
              <DeleteIcon
                onClick={() => {
                  setIsDeleteModalVisible(true);
                  setDeleteId(record?.id);
                }}
                className="font-size-20 delete-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return {
    isShowFilter,
    navigate,
    setIsShowFilter,
    infoSliderVisible,
    setInfoSliderVisible,
    handleCancelDeleteModal,
    deleteExercise,
    isDeleteModalVisible,
    infoContent,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setCategoryValue,
    categoryValue,
    section,
    setSectionValue,
    sectionValue,
    setStatusValue,
    statusValue,
    clearAllFilter,
    selectRow,
    handleMultipleDelete,
    rowSelection,
    columns,
    exerciseData,
    handleCancelMultiDelete,
    tableLoading,
    onChange,
    onChangePage,
    currentPage,
    category,
    handleChangeOfSearch,
    handleKeyPressSearch,
    isMultiDeleteVisible,
    setIsMultiDeleteVisible,
  };
};

export default useExerciseHook;
