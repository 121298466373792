import React from 'react';

import { Route } from 'react-router-dom';

import ChangePassword from '../ChangePassword';
import { router } from 'utils/constants';
import Profile from '../Profile';

const ProfileRoutes = () => {
  return (
    <>
      <Route path={router.profile} element={<Profile />} />
      <Route path={router.changePassword} element={<ChangePassword />} />
    </>
  );
};

export default ProfileRoutes;
