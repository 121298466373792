/* eslint-disable @typescript-eslint/no-explicit-any */
// Built-In
import React, { useEffect, useState } from 'react';

// External
import { message, Modal, Select } from 'antd';

// External
import { GET_SECTION } from '../api';

// Interface
interface IProps {
  open: boolean;
  handleCancel: () => void;
  data: any;
  handleOk: () => void;
  setSectionValue: React.Dispatch<React.SetStateAction<string | null>>;
  sectionValue: string | null;
}

interface IDropDown {
  label: string;
  value: string;
}

const EditItemModel = (props: IProps) => {
  const [section, setSection] = useState<IDropDown[]>([]);

  const { open, handleCancel, data, handleOk, setSectionValue, sectionValue } =
    props;

  const fetchSection = async () => {
    try {
      const res = await GET_SECTION();
      if (res?.status === 1 || res?.status === '1') {
        const tempOption = res?.data?.map((item: any) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setSection(tempOption);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  useEffect(() => {
    if (section.length > 0) {
      setSectionValue(data.sectionId);
    }
  }, [data.sectionId, section.length, setSectionValue]);

  useEffect(() => {
    fetchSection();
  }, []);

  return (
    <Modal
      title={data?.exercise?.exercise_name || data?.workout?.workout_name}
      centered
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Update"
    >
      <div className="d-flex align-items-center w-100">
        <p className="font-size-18 line-height-26 mb-0 width-150">Section:</p>
        <Select
          placeholder="Select Section"
          className="font-size-18 line-height-26 w-100"
          options={section}
          onChange={(e: string) => setSectionValue(e)}
          value={sectionValue}
        />
      </div>
    </Modal>
  );
};

export default EditItemModel;
