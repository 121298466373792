// Builtin
import React from 'react';

// External
import { Input, Table, Tooltip } from 'antd';

// Internal
import ButtonComponent from 'components/button/Button';
import InsideHeader from 'components/inside-header/InsideHeader';
import { SearchIcon } from 'icon/CommonIcon';
import { router } from 'utils/constants';
import PaginationCom from 'components/pagination/Pagination';
import useProgramHook from './hooks/useProgramHook';
import CustomModel from 'components/model/CustomModel';

const Program = () => {
  const {
    columns,
    tempSearch,
    handleOnChange,
    handleKeyPress,
    navigate,
    programData,
    tableLoading,
    handleTableChange,
    onChangePage,
    currentPage,
    setSearchValue,
    deleteProgram,
    handleCancelDeleteModal,
    isDeleteVisible,
    rowSelection,
    selectRow,
    isMultiDeleteVisible,
    setIsMultiDeleteVisible,
    handleMultipleDelete,
    handleCancelMultiDelete,
  } = useProgramHook();

  return (
    <>
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search program by names..."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearchValue(tempSearch)}
              />
            </Tooltip>
          }
          className="input-control"
          type="search"
          allowClear
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            type="primary"
            className="mr-1 width-180"
            onClick={() => navigate(router.createProgram)}
          >
            + Create Program
          </ButtonComponent>
        </div>
      </InsideHeader>

      {/* Multi delete button */}
      {selectRow.length > 0 && (
        <div className="mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className="mb-0 font-weight-600">
                {selectRow.length} - Selected Program
              </h2>
            </div>
            <div>
              <ButtonComponent
                type="primary"
                onClick={() => setIsMultiDeleteVisible(true)}
              >
                Delete Selected Program
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        {/* Data Table */}
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
            columnWidth: 40,
          }}
          columns={columns}
          dataSource={programData?.data}
          pagination={false}
          loading={tableLoading}
          rowKey="id"
          onChange={handleTableChange}
          scroll={{ x: 900 }}
          className="w-100 overflow-auto"
        />

        {/* Pagination */}
        <div className="bg-white w-100 p-2 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {programData?.data.length} of {programData?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={currentPage}
            showLessItems={true}
            total={programData?.total}
          />
        </div>
      </div>

      {/* Delete Model */}
      {isDeleteVisible && (
        <CustomModel
          open={isDeleteVisible}
          handleOk={deleteProgram}
          handleCancel={handleCancelDeleteModal}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}

      {/* Multi Delete Model  */}
      {isMultiDeleteVisible && (
        <CustomModel
          open={isMultiDeleteVisible}
          handleOk={handleMultipleDelete}
          handleCancel={handleCancelMultiDelete}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}
    </>
  );
};

export default Program;
