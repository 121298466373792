/* eslint-disable @typescript-eslint/no-explicit-any */
// Built-In
import React, { useEffect, useState } from 'react';

// External
import { Input, message, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

// Internal
import InsideHeader from '../../components/inside-header/InsideHeader';
import ButtonComponent from '../../components/button/Button';
import SectionCard from './components/SectionCard';
import { SearchIcon } from '../../icon/CommonIcon';
import { router } from '../../utils/constants';
import { GET_SECTION } from './api';

const Section = () => {
  // useState
  const [sectionData, setSectionData] = useState<any>();
  const [search, setSearch] = useState<string | null>(null);
  const [tempSearch, setTempSearch] = useState<string | null>(null);

  // Variable
  const navigate = useNavigate();

  // Function
  const fetchSection = async (searchValue: string | null) => {
    setSectionData([]);
    try {
      const res = await GET_SECTION(searchValue);
      if (res?.status === 1 || res?.status === '1') {
        setSectionData(res?.data);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      setSearch(tempSearch);
    }
  };

  // useEffect
  useEffect(() => {
    fetchSection(search);
  }, [search]);

  return (
    <>
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search exercise/workout by names..."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearch(tempSearch)}
              />
            </Tooltip>
          }
          className="input-control"
          type="search"
          allowClear
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            type="primary"
            className="mr-1 width-180"
            onClick={() => navigate(router.sectionItemAdd)}
          >
            + Add New Item
          </ButtonComponent>
        </div>
      </InsideHeader>

      {sectionData?.map((item: any) => {
        return (
          <SectionCard
            section={item}
            key={item.id}
            fetchSection={fetchSection}
            search={search}
          />
        );
      })}
    </>
  );
};

export default Section;
