// Built - In
import React from 'react';

// External
import { Button, Form, Input, Radio, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// Internal
import InsideHeader from 'components/inside-header/InsideHeader';
import ButtonComponent from 'components/button/Button';
import CustomInput from 'components/input/CustomInput';
import { IWorkoutDetails } from 'features/workout/types';
import useStepTwoHook from 'features/workout/hook/useStepTwoHook';

// Interface
interface IProps {
  onBack: () => void;
  workOutDetails: IWorkoutDetails | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workOutBuilder: any;
}

const Step2 = ({ onBack, workOutDetails, workOutBuilder }: IProps) => {
  const {
    form,
    onFinish,
    uploadBtnPropsImage,
    trainingZone,
    equipment,
    onChange,
    value,
    id,
  } = useStepTwoHook({
    workOutDetails,
    workOutBuilder,
  });
  return (
    <InsideHeader className="add-edit-form box-shadow-2">
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        name="control-hooks"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name="workout_name"
          label="Workout Name"
          className="font-size-18 line-height-26"
        >
          <CustomInput size="large" placeholder="Workout Name" />
        </Form.Item>

        <Form.Item name="duration" label="Workout Duration">
          <CustomInput
            type="number"
            size="large"
            placeholder="Workout Duration"
          />
        </Form.Item>

        <Form.Item name="image" label="Thumbnail Image">
          <Upload
            accept=".png, .jpg, .jpeg"
            listType="picture"
            name="image"
            className="thumb-img"
            {...uploadBtnPropsImage}
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              className="btn-thumb"
            >
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="training_zones"
          label="Training Zone"
          className="font-size-18 line-height-26"
        >
          <Select
            showSearch
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Select Training Zones"
            className="font-size-18 line-height-26"
            options={trainingZone}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item
          name="equipment"
          label="Equipment"
          className="font-size-18 line-height-26"
        >
          <Select
            showSearch
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Select Equipment"
            className="font-size-18 line-height-26"
            options={equipment}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item name="fitness_level" label="Fitness Level">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="Beginner">Beginner</Radio>
            <Radio value="Intermediate">Intermediate</Radio>
            <Radio value="Advance">Advance</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="workout_visibility" label="Workout Visibility">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="public">Public</Radio>
            <Radio value="private">Private</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="cost_type" label="Workout Cost">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="free">free</Radio>
            <Radio value="paid">Paid</Radio>
          </Radio.Group>
        </Form.Item>

        {value === 'paid' && (
          <Form.Item name="workout_cost" label="Workout Cost">
            <CustomInput
              type="number"
              size="large"
              placeholder="Workout Cost"
            />
          </Form.Item>
        )}

        <Form.Item name="description" label="Workout Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>

        <Form.Item name="instruction" label="Workout Instructions">
          <Input.TextArea placeholder="Instructions" />
        </Form.Item>

        <div className="form-btn-group d-flex justify-content-center">
          <ButtonComponent
            className="mr-2 width-150 btn-cancel btn-component"
            style={{ background: 'transparent', color: '#8C8673' }}
            onClick={onBack}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            htmlType="submit"
            type="primary"
            className="btn-save btn-all btn-component width-150"
          >
            {id ? 'Update' : 'Create'}
          </ButtonComponent>
        </div>
      </Form>
    </InsideHeader>
  );
};

export default Step2;
