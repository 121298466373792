/* eslint-disable @typescript-eslint/no-explicit-any */
import { methods, service } from 'service/service';

const GET_EXERCISE = (
  limit: number,
  skip: number,
  search: string | null,
  sort: any | null,
  status: string | null,
  trainingZoneId: string | null,
  equipmentId: string | null,
  exercise_category: string | null,
  sectionId: string | null,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (sort) {
    const name = Object.keys(sort)[0] || 'createdAt';
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }

  if (status) {
    query.status = status;
  }
  if (trainingZoneId) {
    query.trainingZoneId = trainingZoneId;
  }
  if (equipmentId) {
    query.equipmentId = equipmentId;
  }
  if (exercise_category) {
    query.exercise_category = exercise_category;
  }
  if (sectionId) {
    query.sectionId = sectionId;
  }

  return service({ url: 'exercise', method: methods.GET, params: query });
};

const DELETE_EXERCISE = (id: string) =>
  service({ url: `exercise/${id}`, method: methods.DELETE });

const GET_EXERCISE_BY_ID = (id: string | null) =>
  service({ url: `exercise/${id}`, method: methods.GET });

const ADD_EXERCISE = (payload: any) =>
  service({ url: 'exercise', method: methods.POST, data: payload });

const EDIT_EXERCISE = (id: string | null, payload: any) =>
  service({ url: `exercise/${id}`, method: methods.PATCH, data: payload });

const MULTIPLE_DELETE_EXERCISE = (payload: any) =>
  service({ url: 'exercise', method: methods.DELETE, data: payload });

export const EXERCISE = {
  GET_EXERCISE,
  DELETE_EXERCISE,
  GET_EXERCISE_BY_ID,
  ADD_EXERCISE,
  EDIT_EXERCISE,
  MULTIPLE_DELETE_EXERCISE,
};
