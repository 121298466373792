// Built-In
import React from 'react';

// External
import { Modal } from 'antd';

// Interface
interface Iprops {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  title: string;
  description: string;
  okBtnText: string;
}

const CustomModel = (props: Iprops) => {
  const { open, handleOk, handleCancel, title, description, okBtnText } = props;
  return (
    <Modal
      title={title}
      centered
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={okBtnText}
    >
      <p className="mb-0 p-1 font-size-18 text-center">{description}</p>
    </Modal>
  );
};

export default CustomModel;
