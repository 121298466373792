import React from 'react';

import { Row, Col } from 'antd';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const InsideHeader = ({ children, className, style }: any) => {
  return (
    <Row
      className={`bg-white p-2 box-shadow-3 border-radius-5 ${className}`}
      style={style}
    >
      <Col span={24}>{children}</Col>
    </Row>
  );
};

export default InsideHeader;
