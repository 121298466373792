/* eslint-disable @typescript-eslint/no-explicit-any */
// Built in
import React, { useEffect, useState } from 'react';

// External
import { ColumnsType } from 'antd/es/table';
import { message, TablePaginationConfig, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';

// Internal
import { DeleteIcon, EditIcon } from 'icon/CommonIcon';
import { IProgram } from '../types';
import { useNavigate } from 'react-router-dom';
import { IResponse } from 'types';
import { PROGRAM } from '../api';
import TableImage from 'components/table-image/TableImage';
import { router } from 'utils/constants';

const useProgramHook = () => {
  const [tempSearch, setTempSearch] = useState<string | null>(null);

  const [programData, setProgramData] = useState<IResponse<IProgram>>();
  const [currentPage, setCurrentPage] = useState(1);

  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | undefined>('');

  const [selectRow, setSelectRow] = useState<IProgram[]>([]);
  const [selectRowKey, setSelectRowKey] = useState<React.Key[]>([]);

  const [isMultiDeleteVisible, setIsMultiDeleteVisible] =
    useState<boolean>(false);

  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sort, setSort] = useState<any>(null);

  const navigate = useNavigate();

  const columns: ColumnsType<IProgram> = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'media_item',
      align: 'center',
      width: 130,
      render: (thumbnail) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Program Name',
      dataIndex: 'program_name',
      key: 'name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Cost',
      dataIndex: 'program_cost',
      key: 'cost',
      align: 'center',
      width: 150,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Fitness Level',
      dataIndex: 'fitness_level',
      key: 'fitness_level',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 200,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: number, record: IProgram) => {
        return (
          <div className="g-3">
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => navigate(router.programEdit(record?.id))}
                className="font-size-20 edit-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>

            <Tooltip title="Delete">
              <DeleteIcon
                className="font-size-20 delete-btn-hover cursor-pointer mx-1"
                onClick={() => {
                  setIsDeleteVisible(true);
                  setDeleteId(record?.id);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<IProgram> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setSelectRow(selectRowRecord);
      setSelectRowKey(selectedRowKeys);
    },
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      setSearchValue(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      setSearchValue(tempSearch);
    }
  };

  const fetchProgram = async (
    pageP: number,
    pageSizeP: number,
    search: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortP: any,
  ) => {
    setTableLoading(true);
    setProgramData(undefined);
    try {
      const res = await PROGRAM.GET(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        search,
        sortP,
      );
      if (res?.status === 1 || res?.status === '1') {
        setProgramData(res);
      }
    } catch (error) {
      setTableLoading(false);
      message.error(`${error || 'Something went wrong!'}`);
    } finally {
      setTableLoading(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsDeleteVisible(false);
    setDeleteId('');
  };

  const deleteProgram = async () => {
    try {
      const res = await PROGRAM.DELETE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId('');
        setIsDeleteVisible(false);
        let page = currentPage;
        if (programData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchProgram(page, pageSize, searchValue, sort);
        }
        message.success(res?.message);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const handleCancelMultiDelete = () => {
    setIsMultiDeleteVisible(false);
    setSelectRow([]);
    setSelectRowKey([]);
  };

  const handleMultipleDelete = async () => {
    const payload = selectRow.map((item) => {
      return { id: item.id, deletedAt: new Date() };
    });
    try {
      const res = await PROGRAM.MULTIDELETE(payload);
      if (res?.status === 1 || res?.status === '1') {
        handleCancelMultiDelete();
        let page = currentPage;
        if (programData?.data?.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchProgram(page, pageSize, searchValue, sort);
        }
        message.success('Record deleted successfully !');
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    if (sorter?.order === 'ascend') {
      setSort({ [`${sorter?.field}`]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [`${sorter?.field}`]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setCurrentPage(pageT);
    setPageSize(pageSizeT);
  };

  useEffect(() => {
    fetchProgram(currentPage, pageSize, searchValue, sort);
  }, [currentPage, pageSize, searchValue, sort]);

  return {
    columns,
    tempSearch,
    setTempSearch,
    handleOnChange,
    handleKeyPress,
    navigate,
    programData,
    tableLoading,
    handleTableChange,
    onChangePage,
    currentPage,
    setSearchValue,
    deleteProgram,
    handleCancelDeleteModal,
    isDeleteVisible,
    rowSelection,
    selectRow,
    isMultiDeleteVisible,
    setIsMultiDeleteVisible,
    handleMultipleDelete,
    handleCancelMultiDelete,
  };
};

export default useProgramHook;
