/* eslint-disable @typescript-eslint/no-explicit-any */
import { methods, service } from './service';

export const GET_TRAINING_ZONE = (
  limit: string | number,
  skip: string | number,
  search: string | null,
  sort: any,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (sort) {
    const name = Object.keys(sort)[0] || 'createdAt';
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: 'training-zone', method: methods.GET, params: query });
};

export const GET_EQUIPMENT = (
  limit: string | number,
  skip: string | number,
  search: string | null,
  sort: any | null,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (sort) {
    const name = Object.keys(sort)[0] || 'createdAt';
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: 'equipments', method: methods.GET, params: query });
};

export const LOGOUT = () => service({ url: 'logout', method: methods.GET });
