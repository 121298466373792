// Built - In
import { useEffect, useState } from 'react';

// External
import { useNavigate, useParams } from 'react-router-dom';
import { Form, message, Upload, UploadFile, UploadProps } from 'antd';
import type { RadioChangeEvent } from 'antd';

// Internal
import { useAuth } from 'context/AuthContext';
import { router } from 'utils/constants';
import { IDropDown } from 'types';
import { ADD_WORKOUT, EDIT_WORKOUT } from 'features/workout/api';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import { IWorkoutDetails } from 'features/workout/types';

// Interface
interface IProps {
  workOutDetails: IWorkoutDetails | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workOutBuilder: any;
}

const useStepTwoHook = ({ workOutDetails, workOutBuilder }: IProps) => {
  // useState
  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [imageId, setImageId] = useState<string | null>(null);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);
  const [fileListImage, setFileImage] = useState<UploadFile[]>([]);
  const [value, setValue] = useState('active');

  // Variable
  const { token } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id = null } = useParams();

  const uploadBtnPropsImage: UploadProps = {
    multiple: false,
    fileList: fileListImage,
    action: `${process.env.REACT_APP_API_ENDPOINT}/upload-image`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload(file) {
      const type =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg';
      if (!type) {
        message.error('Only .jpeg, .png, .jpg files are allowed!');
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileImage([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setImageId(info.file.response?.data?.mediaItem?.id);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove() {
      setFileImage([]);
      setImageId(null);
    },
  };

  // eslint-disable-next-line
  const convertIntoMinutes = (time: any, isHour: any) => {
    // eslint-disable-next-line
    let minutes: any = Math.floor(time / 60);
    // eslint-disable-next-line
    let seconds: any = time % 60;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return isHour ? `${minutes}:${seconds}:00` : `00:${minutes}:${seconds}`;
  };

  // Function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (formValue: any) => {
    const modified = workOutBuilder.map(
      (item: { id: string; workout_durations: [] }, i: number) => {
        return {
          position_no: i,
          exerciseId: item.id,
          workout_durations: item.workout_durations,
        };
      },
    );
    const payload = {
      workout_name: formValue.workout_name,
      duration: convertIntoMinutes(formValue.duration, true),
      workoutThumbnailId: imageId,
      training_zones: formValue.training_zones,
      equipment: formValue.equipment,
      fitness_level: formValue.fitness_level,
      workout_visibility: formValue.workout_visibility,
      cost_type: formValue.cost_type,
      description: formValue.description,
      instruction: formValue.instruction,
      workout_cost: formValue.workout_cost,
      workout_sets: modified,
    };
    try {
      let res;
      if (id) {
        res = await EDIT_WORKOUT(id, payload);
      } else {
        res = await ADD_WORKOUT(payload);
      }
      if (res.status === 1 || res.status === '1') {
        message.success(res.message);
        navigate(router.workout);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  const onChange = (e: RadioChangeEvent) => setValue(e.target.value);

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  // useEffect
  useEffect(() => {
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  useEffect(() => {
    if (workOutDetails && trainingZone.length > 0 && equipment.length > 0) {
      form.setFieldsValue(workOutDetails);
      setValue(workOutDetails.cost_type);
      if (workOutDetails?.thumbnail) {
        setFileImage([
          {
            uid: '-1',
            name: 'image',
            status: 'done',
            url: workOutDetails?.thumbnail?.media,
          },
        ]);
        setImageId(workOutDetails?.thumbnail?.id);
      }
    }
  }, [equipment.length, form, trainingZone.length, workOutDetails]);

  return {
    form,
    onFinish,
    uploadBtnPropsImage,
    trainingZone,
    equipment,
    onChange,
    value,
    id,
  };
};

export default useStepTwoHook;
