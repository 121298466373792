/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { Input, message, Table, TablePaginationConfig, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../components/button/Button';
import InsideHeader from '../../components/inside-header/InsideHeader';
import CustomModel from '../../components/model/CustomModel';
import { DeleteIcon, EditIcon, SearchIcon } from '../../icon/CommonIcon';
import { DELETE_USER, GET_USERS } from './api';
import PaginationCom from 'components/pagination/Pagination';

const Users = () => {
  const [usersData, setUsersData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState<any>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const [tableDataLength, setTableDataLength] = useState(0);
  const navigate = useNavigate();

  const fetchUsers = async (
    pageP = 1,
    pageSizeP = 10,
    search: string | null = null,
    sortP: any = null,
  ) => {
    setTableLoading(true);
    try {
      const res = await GET_USERS(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        search,
        sortP,
      );
      if (res?.status === 1 || res?.status === '1') {
        setUsersData(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (error) {
      setTableLoading(false);
      message.error(`${error || 'Something went wrong !'}`);
    } finally {
      setTableLoading(false);
    }
  };

  const deleteUsers = async () => {
    try {
      const res = await DELETE_USER(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId('');
        setIsDeleteModalVisible(false);
        // If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (usersData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchUsers(page, pageSize, searchValue, sort);
        }
        message.success(res?.message);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setDeleteId('');
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setCurrentPage(pageT);
    setPageSize(pageSizeT);
  };

  const onChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, pageSize, searchValue, sort);
  }, [currentPage, pageSize, searchValue, sort]);

  const columns: any = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 130,
      render: (_: number, record: any) => (
        <p>
          {record?.first_name} {record?.last_name}
        </p>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'userName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: number, record: any) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => navigate(`/users/edit/${record?.id}`)}
                className="font-size-20 edit-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                onClick={() => {
                  setIsDeleteModalVisible(true);
                  setDeleteId(record?.id);
                }}
                className="font-size-20 delete-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search Users.."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearchValue(tempSearch)}
              />
            </Tooltip>
          }
          className="input-control"
          type="search"
          onChange={(e: any) => {
            if (!e?.target?.value) {
              setSearchValue(null);
              setTempSearch(null);
            } else {
              setTempSearch(e?.target?.value);
            }
          }}
          onKeyPress={(e: any) => {
            if (e?.key === 'Enter' && e.target.value) {
              setCurrentPage(1);
              setSearchValue(tempSearch);
            }
          }}
          allowClear
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            type="primary"
            className="mr-1 width-180"
            onClick={() => {
              navigate('/users/add');
            }}
          >
            + Add New Users
          </ButtonComponent>
        </div>
      </InsideHeader>

      {/* Data Table */}
      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        <Table
          columns={columns}
          dataSource={usersData?.data}
          pagination={false}
          loading={tableLoading}
          rowKey="id"
          className="w-100 overflow-auto"
          onChange={onChange}
          scroll={{ x: 900 }}
        />

        {/* Pagination */}

        <div className="bg-white p-2 w-100 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {tableDataLength} of {usersData?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={currentPage}
            showLessItems={true}
            total={usersData?.total}
          />
        </div>
      </div>
      <CustomModel
        open={isDeleteModalVisible}
        handleOk={deleteUsers}
        handleCancel={handleCancelDeleteModal}
        title="Delete"
        description="Are you sure you want to delete this?"
        okBtnText="Delete"
      />
    </>
  );
};

export default Users;
