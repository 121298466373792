// Build-In
import React from 'react';

// External
import { Route } from 'react-router-dom';

// Internal
import WorkOutAddEdit from '../workout-add-edit/WorkOutAddEdit';
import { router } from 'utils/constants';
import Workouts from '../Workout';

const AppRoutes = () => {
  return (
    <>
      <Route path={router.workout} element={<Workouts />} />
      <Route path={router.workoutAdd} element={<WorkOutAddEdit />} />
      <Route path={router.workoutEdit()} element={<WorkOutAddEdit />} />
    </>
  );
};

export default AppRoutes;
