// Build-In
import React from 'react';

// External
import { Route } from 'react-router-dom';

// Internal
import { router } from 'utils/constants';
import AddItem from '../components/AddItem';
import SeeAllSection from '../SeeAllSection';
import Section from '../Section';

const AppRoutes = () => {
  return (
    <>
      <Route path={router.section} element={<Section />} />
      <Route path={router.sectionSeeAll()} element={<SeeAllSection />} />
      <Route path={router.sectionItemAdd} element={<AddItem />} />
    </>
  );
};

export default AppRoutes;
