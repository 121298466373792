/* eslint-disable @typescript-eslint/no-explicit-any */
// built in
import { useEffect, useState } from 'react';

// External
import {
  Form,
  message,
  RadioChangeEvent,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';

// internal
import { useAuth } from 'context/AuthContext';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import { IDropDown } from 'types';
import { PROGRAM } from '../api';
import { router } from 'utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { FormValue, IProgram } from '../types';

const useStepTwoHook = ({ programBuilder, programDetails }: any) => {
  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [imageId, setImageId] = useState<string | null>(null);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);
  const [fileListImage, setFileImage] = useState<UploadFile[]>([]);
  const [value, setValue] = useState('active');
  const { id = null } = useParams();
  const { token } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const uploadBtnPropsImage: UploadProps = {
    multiple: false,
    fileList: fileListImage,
    action: `${process.env.REACT_APP_API_ENDPOINT}/upload-image`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload(file) {
      const type =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg';
      if (!type) {
        message.error('Only .jpeg, .png, .jpg files are allowed!');
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileImage([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setImageId(info.file.response?.data?.mediaItem?.id);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove() {
      setFileImage([]);
      setImageId(null);
    },
  };

  const onChange = (e: RadioChangeEvent) => setValue(e.target.value);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (formValue: FormValue) => {
    const modifiedUpdate: any = programBuilder.map(
      (item: { week_name: string ,week_no: number; program_week_workouts: [] }) => {
        return {
          week_name: item?.week_name,
          week_no: item?.week_no,
          program_week_workouts: item?.program_week_workouts?.map(
            (item: IProgram, i: number) => {
              return {
                position_no: i + 1,
                workoutId: item?.workout?.id,
              };
            },
          ),
        };
      },
    );

    const payload = {
      program_name: formValue?.program_name,
      program_duration: formValue?.program_duration,
      programThumbnailId: imageId,
      training_zones: formValue?.training_zones,
      equipment: formValue?.equipment,
      fitness_level: formValue?.fitness_level,
      program_visibility: formValue?.program_visibility,
      cost_type: formValue?.cost_type,
      description: formValue?.description,
      program_cost: formValue?.program_cost,
      program_weeks: modifiedUpdate,
    };
    try {
      let res;
      if (id) {
        res = await PROGRAM.EDIT(id, payload);
      } else {
        res = await PROGRAM.CREATE(payload);
      }

      if (res.status === 1 || res.status === '1') {
        message.success(res.message);
        navigate(router.program);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  // useEffect
  useEffect(() => {
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  useEffect(() => {
    if (programDetails && trainingZone.length > 0 && equipment.length > 0) {
      form.setFieldsValue(programDetails);
      setValue(programDetails.cost_type);
      if (programDetails?.thumbnail) {
        setFileImage([
          {
            uid: '-1',
            name: 'image',
            status: 'done',
            url: programDetails?.thumbnail?.media,
          },
        ]);
        setImageId(programDetails?.thumbnail?.id);
      }
    }
  }, [equipment.length, form, trainingZone.length, programDetails]);

  return {
    form,
    onChange,
    value,
    uploadBtnPropsImage,
    trainingZone,
    equipment,
    imageId,
    onFinish,
    id,
  };
};

export default useStepTwoHook;
