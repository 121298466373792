/* eslint-disable @typescript-eslint/no-explicit-any */
// Build-In
import React from 'react';

// External
import { Col, Drawer, Row, Tag } from 'antd';

// Internal
import Logo from 'assets/images/logo.png';
import { IExercise } from '../types';

// interface
interface InfoDrawerProps {
  setInfoSliderVisible: (open: boolean) => void;
  infoSliderVisible: boolean;
  data: IExercise | undefined;
}

const InfoDrawer = (props: InfoDrawerProps) => {
  const { setInfoSliderVisible, infoSliderVisible, data } = props;

  return (
    <Drawer
      title="Exercise Info"
      placement="right"
      onClose={() => setInfoSliderVisible(false)}
      visible={infoSliderVisible}
      className="info-drawer"
    >
      <div className="text-center">
        {data?.thumbnail?.media ? (
          <img
            src={data?.thumbnail?.media}
            alt=""
            width="150px"
            height="150px"
            className="object-fit-contain"
            crossOrigin="anonymous"
          />
        ) : (
          <img
            src={Logo}
            alt=""
            width="150px"
            height="150px"
            className="object-fit-contain"
            crossOrigin="anonymous"
          />
        )}
      </div>

      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Exercise Name
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row">
          :
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className="gutter-row">
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.exercise_name}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Training Zone
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          {data?.exercise_training_zones?.map((record: any) => {
            return (
              <Tag
                key={record.id}
                color="#F8F7F6"
                className="border-radius-8"
                style={{ border: '1px solid #8C8673' }}
              >
                <h2 className="font-size-18 line-height-26 mb-0 text-grey">
                  {record?.training_zone?.name}
                </h2>
              </Tag>
            );
          })}
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Equipment
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          {data?.exercise_equipments?.map((record: any) => {
            return (
              <Tag
                key={record?.id}
                color="#F8F7F6"
                className="border-radius-8"
                style={{ border: '1px solid #8C8673' }}
              >
                <h2 className="font-size-18 line-height-26 mb-0 text-grey">
                  {record?.equipment?.name}
                </h2>
              </Tag>
            );
          })}
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Exercise Category
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.exercise_category}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Exercise Description
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.description}
          </h2>
        </Col>
      </Row>

      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Status
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">{data?.status}</h2>
        </Col>
      </Row>
    </Drawer>
  );
};

export default InfoDrawer;
