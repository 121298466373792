/* eslint-disable @typescript-eslint/no-explicit-any */
// Built-In
import React, { useEffect, useState } from 'react';

// External
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { message, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

// Internal
import { router } from 'utils/constants';
import { DeleteIcon, EditIcon } from 'icon/CommonIcon';
import { IDropDown, IResponse } from 'types';
import { DELETE_WORKOUT, GET_WORKOUTS, MULTIPLE_DELETE_WORKOUT } from '../api';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import { IWorkoutDetails } from '../types';
import TableImage from 'components/table-image/TableImage';

const useWorkOutHook = () => {
  // useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workoutsData, setWorkoutsData] =
    useState<IResponse<IWorkoutDetails>>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [tempSearch, setTempSearch] = useState<string | null>(null);
  const [sort, setSort] = useState<any>(null);

  const [isMultiDeleteVisible, setIsMultiDeleteVisible] =
    useState<boolean>(false);
  const [selectRow, setSelectRow] = useState<IWorkoutDetails[]>([]);
  const [selectRowKey, setSelectRowKey] = useState<React.Key[]>([]);

  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | undefined>('');

  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);

  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [trainingZoneValue, setTrainingZoneValue] = useState<string | null>(
    null,
  );
  const [equipmentValue, setEquipmentValue] = useState<string | null>(null);
  const [fitnessLevelValue, setFitnessLevelValue] = useState<string | null>(
    null,
  );

  // Variable
  const navigate = useNavigate();

  const rowSelection: TableRowSelection<IWorkoutDetails> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setSelectRow(selectRowRecord);
      setSelectRowKey(selectedRowKeys);
    },
  };

  const columns: ColumnsType<IWorkoutDetails> = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 130,
      render: (thumbnail) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Workout Name',
      dataIndex: 'workout_name',
      key: 'workoutName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Equipment',
      dataIndex: 'workout_equipments',
      key: 'workout_equipments',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: false,
      render: (workout_equipments) => {
        const modified = workout_equipments
          ?.map((item: { equipment: { name: string } }) => {
            return item?.equipment?.name;
          })
          .join(',');
        return modified;
      },
    },
    {
      title: 'Cost',
      dataIndex: 'workout_cost',
      key: 'workout_cost',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 100,
    },
    {
      title: 'Fitness Level',
      dataIndex: 'fitness_level',
      key: 'fitnessLevel',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: number, record: IWorkoutDetails) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => navigate(router.workoutEdit(record?.id))}
                className="font-size-20 edit-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                onClick={() => {
                  setIsDeleteVisible(true);
                  setDeleteId(record?.id);
                }}
                className="font-size-20 delete-btn-hover cursor-pointer mx-1"
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Function
  const fetchWorkouts = async (
    pageP: number,
    pageSizeP: number,
    fitness: string | null,
    trainingZoneId: string | null,
    equipmentId: string | null,
    search: string | null,
    sortP: any,
  ) => {
    setIsLoading(true);
    setWorkoutsData(undefined);
    try {
      const res = await GET_WORKOUTS(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        fitness,
        trainingZoneId,
        equipmentId,
        search,
        sortP,
      );
      if (res?.status === 1 || res?.status === '1') {
        setWorkoutsData(res);
      }
    } catch (err) {
      setIsLoading(false);
      message.error(`${err || 'Something went wrong!'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const deleteWorkouts = async () => {
    try {
      const res = await DELETE_WORKOUT(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId('');
        setIsDeleteVisible(false);
        let page = currentPage;
        if (workoutsData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchWorkouts(
            page,
            pageSize,
            fitnessLevelValue,
            trainingZoneValue,
            equipmentValue,
            searchValue,
            sort,
          );
        }
        message.success(res?.message);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsDeleteVisible(false);
    setDeleteId('');
  };

  const handleCancelMultiDelete = () => {
    setIsMultiDeleteVisible(false);
    setSelectRow([]);
    setSelectRowKey([]);
  };

  const handleMultipleDelete = async () => {
    const payload = selectRow.map((item) => {
      return { id: item.id, deletedAt: new Date() };
    });
    try {
      const res = await MULTIPLE_DELETE_WORKOUT(payload);
      if (res?.status === 1 || res?.status === '1') {
        handleCancelMultiDelete();
        let page = currentPage;
        if (workoutsData?.data?.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else {
          fetchWorkouts(
            page,
            pageSize,
            fitnessLevelValue,
            trainingZoneValue,
            equipmentValue,
            searchValue,
            sort,
          );
        }
        message.success('Record deleted successfully !');
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setCurrentPage(pageT);
    setPageSize(pageSizeT);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    if (sorter?.order === 'ascend') {
      setSort({ [`${sorter?.field}`]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [`${sorter?.field}`]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const clearAllFilter = () => {
    setFitnessLevelValue(null);
    setTrainingZoneValue(null);
    setEquipmentValue(null);
  };

  const handleChangeOfSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      setSearchValue(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      setCurrentPage(1);
      setSearchValue(tempSearch);
    }
  };

  // useEffect
  useEffect(() => {
    fetchWorkouts(
      currentPage,
      pageSize,
      fitnessLevelValue,
      trainingZoneValue,
      equipmentValue,
      searchValue,
      sort,
    );
  }, [
    currentPage,
    equipmentValue,
    fitnessLevelValue,
    pageSize,
    searchValue,
    sort,
    trainingZoneValue,
  ]);

  useEffect(() => {
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  return {
    setSearchValue,
    tempSearch,
    setIsShowFilter,
    isShowFilter,
    navigate,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setFitnessLevelValue,
    fitnessLevelValue,
    clearAllFilter,
    selectRow,
    setIsMultiDeleteVisible,
    rowSelection,
    columns,
    workoutsData,
    isLoading,
    handleTableChange,
    onChangePage,
    currentPage,
    isDeleteVisible,
    deleteWorkouts,
    handleCancelDeleteModal,
    isMultiDeleteVisible,
    handleMultipleDelete,
    handleCancelMultiDelete,
    handleChangeOfSearch,
    handleKeyPress,
  };
};

export default useWorkOutHook;
