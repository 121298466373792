import React from 'react';

const IconUpload = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4.6665L8.5 2.6665M8.5 2.6665L6.5 4.6665M8.5 2.6665V10.6665M5 6.6665H4.5C3.39543 6.6665 2.5 7.56193 2.5 8.6665V10.1332C2.5 11.2533 2.5 11.8133 2.71799 12.2412C2.90973 12.6175 3.21569 12.9234 3.59202 13.1152C4.01984 13.3332 4.5799 13.3332 5.7 13.3332H11.3C12.4201 13.3332 12.9802 13.3332 13.408 13.1152C13.7843 12.9234 14.0903 12.6175 14.282 12.2412C14.5 11.8133 14.5 11.2533 14.5 10.1332V8.6665C14.5 7.56193 13.6046 6.6665 12.5 6.6665H12"
        stroke="#FBC90B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconUpload;
