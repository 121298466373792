/* eslint-disable @typescript-eslint/no-explicit-any */
// Built - In
import React from 'react';

// External
import { Col, Input, Row, Tooltip, Select, Radio, Table, Button } from 'antd';

// Internal
import WorkOutBuilder from 'features/workout/components/WorkOutBuilder';
import InsideHeader from 'components/inside-header/InsideHeader';
import ButtonComponent from 'components/button/Button';
import { FilterIcon, SearchIcon } from 'icon/CommonIcon';
import useStepOneHook from 'features/workout/hook/useStepOneHook';
import PaginationCom from 'components/pagination/Pagination';

interface IProps {
  onNext: () => void;
  onBack: () => void;
  workOutBuilder: any;
  setWorkOutBuilder: (value: any) => void;
}

const category = [
  {
    id: 1,
    label: 'Beginner',
    value: 'Beginner',
  },
  {
    id: 2,
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    id: 3,
    label: 'Advanced',
    value: 'Advanced',
  },
  {
    id: 4,
    label: 'Everyone',
    value: 'Everyone',
  },
];

const Step1 = ({
  onNext,
  onBack,
  workOutBuilder,
  setWorkOutBuilder,
}: IProps) => {
  const {
    handleChangeOfSearch,
    tempSearch,
    handleSearch,
    setIsShowFilter,
    isShowFilter,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    equipmentValue,
    setEquipmentValue,
    setCategoryValue,
    categoryValue,
    section,
    setSectionValue,
    sectionValue,
    setStatusValue,
    statusValue,
    clearAllFilter,
    columns,
    exercise,
    isLoading,
    onChangePage,
    page,
    deleteWorkOutBuilder,
  } = useStepOneHook({
    workOutBuilder,
    setWorkOutBuilder,
  });

  return (
    <>
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search Exercise by names..."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon style={{ color: '#8f9bb3' }} />
            </Tooltip>
          }
          className="input-control"
          type="search"
          onChange={handleChangeOfSearch}
          value={tempSearch || ''}
          onKeyPress={(e) => {
            if (e?.key === 'Enter') {
              handleSearch();
            }
          }}
          allowClear
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            type="secondary"
            className="mr-1 width-180 d-flex align-items-center justify-content-center gap-10"
            icon={<FilterIcon />}
            onClick={() => setIsShowFilter(!isShowFilter)}
          >
            Exercise Filter
          </ButtonComponent>
        </div>
      </InsideHeader>
      {isShowFilter && (
        <InsideHeader className="mt-3 px-24-30">
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Category"
                className="font-size-18 line-height-26 w-100"
                options={category}
                onChange={(e) => setCategoryValue(e)}
                value={categoryValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Section"
                className="font-size-18 line-height-26  w-100"
                options={section}
                onChange={(e) => setSectionValue(e)}
                value={sectionValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center g-3 gutter-row mb-1"
            >
              <div className="w-100">
                <span className="font-size-18 mb-0 line-height-26 mr-1">
                  Status
                </span>
                <Radio.Group
                  onChange={(e) => setStatusValue(e.target.value)}
                  value={statusValue}
                >
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">InActive</Radio>
                </Radio.Group>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              <Button type="link" onClick={clearAllFilter}>
                Clear All
              </Button>
            </Col>
          </Row>
        </InsideHeader>
      )}

      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        <Table
          className="w-100 overflow-auto"
          rowKey="id"
          columns={columns}
          dataSource={exercise?.data}
          pagination={false}
          loading={isLoading}
          scroll={{ x: 900 }}
        />

        <div className="bg-white w-100 p-2 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {exercise?.data?.length} of {exercise?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={page}
            showLessItems={true}
            total={exercise?.total}
          />
        </div>
      </div>

      {/* Workout Builder */}
      <Row className="bg-white p-2 box-shadow-3 border-radius-5 mt-2 fix-width">
        <Col span={24} className="scroll">
          {workOutBuilder.length > 0 && (
            <>
              <div className="bg-grey-head mb-3">
                <p className="mb-0 padding-head font-size-18 line-height-26 font-weight-600">
                  Workout Builder
                </p>
              </div>

              <WorkOutBuilder
                workOutBuilder={workOutBuilder}
                deleteWorkOutBuilder={deleteWorkOutBuilder}
                setWorkOutBuilder={setWorkOutBuilder}
              />
            </>
          )}

          <div className="form-btn-group">
            <ButtonComponent
              className="mr-2 width-150 btn-cancel btn-component"
              style={{ background: 'transparent', color: '#8C8673' }}
              onClick={onBack}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              htmlType="submit"
              type="primary"
              className="btn-save btn-all btn-component"
              onClick={onNext}
            >
              Next
            </ButtonComponent>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Step1;
