// Built-In
import React from 'react';

// External
import { Button, Col, Input, Row, Select, Table, Tooltip } from 'antd';

// Internal
import ButtonComponent from 'components/button/Button';
import InsideHeader from 'components/inside-header/InsideHeader';
import CustomModel from 'components/model/CustomModel';
import { router } from 'utils/constants';
import { FilterIcon, SearchIcon } from 'icon/CommonIcon';
import useWorkOutHook from './hook/useWorkOutHook';
import PaginationCom from 'components/pagination/Pagination';

// Variable
const fitnessLevel = [
  {
    label: 'Beginner',
    value: 'Beginner',
  },
  {
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    label: 'Advance',
    value: 'Advance',
  },
];

const Workouts = () => {
  const {
    setSearchValue,
    tempSearch,
    setIsShowFilter,
    isShowFilter,
    navigate,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setFitnessLevelValue,
    fitnessLevelValue,
    clearAllFilter,
    selectRow,
    setIsMultiDeleteVisible,
    rowSelection,
    columns,
    workoutsData,
    isLoading,
    handleTableChange,
    onChangePage,
    currentPage,
    isDeleteVisible,
    deleteWorkouts,
    handleCancelDeleteModal,
    isMultiDeleteVisible,
    handleMultipleDelete,
    handleCancelMultiDelete,
    handleChangeOfSearch,
    handleKeyPress,
  } = useWorkOutHook();

  return (
    <>
      {/* Header */}
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search Workouts by names..."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearchValue(tempSearch)}
              />
            </Tooltip>
          }
          className="input-control"
          type="search"
          onChange={handleChangeOfSearch}
          onKeyPress={handleKeyPress}
          allowClear
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            className="btn-inside-header mr-1 mb-1 border-radius-8 text-black btn-component d-flex align-items-center justify-content-center gap-10"
            onClick={() => setIsShowFilter(!isShowFilter)}
          >
            <FilterIcon style={{ color: '#2B2923' }} /> Workout Filter
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            className="mr-1 width-180"
            onClick={() => navigate(router.workoutAdd)}
          >
            + Create Workouts
          </ButtonComponent>
        </div>
      </InsideHeader>

      {isShowFilter && (
        <InsideHeader className="mt-3 px-24-30">
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Fitness Level"
                className="font-size-18 line-height-26 w-100"
                options={fitnessLevel}
                onChange={(e) => setFitnessLevelValue(e)}
                value={fitnessLevelValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              <Button type="link" onClick={clearAllFilter}>
                Clear All
              </Button>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {/* Multi delete button */}
      {selectRow.length > 0 && (
        <div className="mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className="mb-0 font-weight-600">
                {selectRow.length} - Selected Workouts
              </h2>
            </div>
            <div>
              <ButtonComponent
                type="primary"
                onClick={() => setIsMultiDeleteVisible(true)}
              >
                Delete Selected Workouts
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}

      {/* Data Table */}
      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
            columnWidth: 40,
          }}
          columns={columns}
          dataSource={workoutsData?.data}
          pagination={false}
          loading={isLoading}
          rowKey="id"
          className="w-100 overflow-auto"
          scroll={{ x: 900 }}
          onChange={handleTableChange}
        />

        {/* Pagination */}
        <div className="bg-white w-100 p-2 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {workoutsData?.data.length} of {workoutsData?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={currentPage}
            showLessItems={true}
            total={workoutsData?.total}
          />
        </div>
      </div>

      {/* Delete Model */}
      {isDeleteVisible && (
        <CustomModel
          open={isDeleteVisible}
          handleOk={deleteWorkouts}
          handleCancel={handleCancelDeleteModal}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}

      {/* Multi Delete Model  */}
      {isMultiDeleteVisible && (
        <CustomModel
          open={isMultiDeleteVisible}
          handleOk={handleMultipleDelete}
          handleCancel={handleCancelMultiDelete}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}
    </>
  );
};

export default Workouts;
