// Built in
import React from 'react';

// External
import { Steps } from 'antd';

// Internal
import useAddEditHook from '../hooks/useAddEditHook';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';

const ProgramAddEdit = () => {
  const {
    step,
    onNext,
    onBack,
    programBuilder,
    setProgramBuilder,
    programDetails,
  } = useAddEditHook();
  return (
    <>
      <div
        style={{
          maxWidth: '90%',
          width: '500px',
          margin: '30px auto',
        }}
      >
        <Steps current={step}>
          <Steps.Step title="Add Program Weeks" className="cursor-pointer" />
          <Steps.Step title="Program Details" className="cursor-pointer" />
        </Steps>
      </div>

      <div>
        {/* Step1 */}
        {step === 0 && (
          <Step1
            onNext={onNext}
            onBack={onBack}
            programBuilder={programBuilder}
            setProgramBuilder={setProgramBuilder}
          />
        )}
        {/* Step2 */}
        {step === 1 && (
          <Step2
            onBack={onBack}
            programBuilder={programBuilder}
            programDetails={programDetails}
          />
        )}
      </div>
    </>
  );
};

export default ProgramAddEdit;
