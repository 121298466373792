/* eslint-disable @typescript-eslint/no-explicit-any */
// Builtin
import React from 'react';

// External
import { Button } from 'antd';

// Internal
import ArrowDownIcon from 'features/workout/icon/ArrowDownIcon';
import ArrowUpIcon from 'features/workout/icon/ArrowUpIcon';
import TrashIcon from 'features/workout/icon/TrashIcon';
import { DeleteIcon, EditIcon } from 'icon/CommonIcon';
import pic from 'assets/images/photo.png';
import { IProgram, IProgramWeeks } from '../types';

interface ProgramBuilders {
  programBuilder: IProgramWeeks[];
  setProgramBuilder: (value: any) => void;
  setWeekSliderVisible: (value: boolean) => void;
  setMainIndex: any;
  setEditData: any;
  setProgramName: any;
  setIsAdd: (value: boolean) => void;
}

const ProgramBuilder = ({
  programBuilder,
  setProgramBuilder,
  setWeekSliderVisible,
  setMainIndex,
  setEditData,
  setProgramName,
  setIsAdd,
}: ProgramBuilders) => {
  const handleArrowUp = (index: number, mainIndex: number) => {
    const temp = [...programBuilder];
    const cutOut = temp[mainIndex]?.program_week_workouts?.splice(index, 1)[0];
    temp[mainIndex]?.program_week_workouts?.splice(index - 1, 0, cutOut);
    setProgramBuilder(temp);
  };

  const handleDown = (index: number, mainIndex: number) => {
    const temp = [...programBuilder];
    const cutOut = temp[mainIndex]?.program_week_workouts?.splice(index, 1)[0];
    temp[mainIndex]?.program_week_workouts?.splice(index + 1, 0, cutOut);
    setProgramBuilder(temp);
  };

  const handleDeleteWeek = (id: any, mainIndex: number) => {
    const temp: any = [...programBuilder];
    const presentItem = temp[mainIndex]?.program_week_workouts?.filter(
      (item: any) => item?.position_no !== id,
    );
    temp[mainIndex].program_week_workouts = presentItem;

    setProgramBuilder(temp);
  };

  const handleDeleteWeekProgram = (weekName: string) => {
    const temp = programBuilder.filter(
      (item: { week_name: string }) => item?.week_name !== weekName,
    );

    temp?.map((singleItem: any, i) => {
      singleItem.week_no = i + 1;
    });

    temp?.map((singleItem: any, i) => {
      singleItem.tempId = i + 1;
    });

    setProgramBuilder(temp);
  };

  const handleEditWeekProgram = (
    mainIndex: number,
    week_name: string,
    item_week_workouts: any,
  ) => {
    setEditData(null);
    setIsAdd(false);
    setWeekSliderVisible(true);
    setMainIndex(mainIndex);
    setEditData(item_week_workouts);
    setProgramName(week_name);
  };

  return (
    <>
      {programBuilder.map((item: IProgramWeeks, mainIndex: number) => {
        return (
          <div key={item?.id} className="mb-3">
            <div className="w-100 d-flex align-center justify-content-between px-3 py-12px bg-program-builder">
              <p className="font-size-18 line-height-26 text-black-dark mb-0">
                {item.week_name}
              </p>
              <div className="d-flex g-16">
                <div
                  onClick={() =>
                    handleEditWeekProgram(
                      mainIndex,
                      item?.week_name,
                      item?.program_week_workouts,
                    )
                  }
                  className="p-8px bg-white border-dark border-radius-4 cursor-pointer"
                >
                  <EditIcon />
                </div>
                <div
                  onClick={() => handleDeleteWeekProgram(item?.week_name)}
                  className="p-8px bg-white border-dark border-radius-4 cursor-pointer"
                >
                  <DeleteIcon />
                </div>
              </div>
            </div>

            {item?.program_week_workouts?.map((item: IProgram, i: number) => {
              return (
                <div
                  className="p-30 bg-white d-flex justify-content-between"
                  key={item.id}
                >
                  <div className="d-flex g-3 align-items-center">
                    {item?.workout?.thumbnail?.media ? (
                      <img
                        src={item?.workout?.thumbnail?.media}
                        alt="Images"
                        crossOrigin="anonymous"
                        style={{ width: '100px', height: '100px' }}
                      />
                    ) : (
                      <img src={pic} alt="Images" crossOrigin="anonymous" />
                    )}
                    <p
                      className="font-size-18 line-height-26 text-black-dark mb-0"
                      style={{ width: '72%' }}
                    >
                      {item?.workout?.workout_name}
                    </p>
                  </div>
                  <div className="d-flex g-16 align-items-center">
                    <Button
                      size="large"
                      onClick={() => handleArrowUp(i, mainIndex)}
                      disabled={i === 0}
                    >
                      <ArrowUpIcon />
                    </Button>
                    <Button
                      size="large"
                      onClick={() => handleDown(i, mainIndex)}
                      disabled={
                        i ===
                        programBuilder[mainIndex]?.program_week_workouts
                          ?.length -
                          1
                      }
                    >
                      <ArrowDownIcon />
                    </Button>
                    <Button
                      size="large"
                      onClick={() =>
                        handleDeleteWeek(item?.position_no, mainIndex)
                      }
                    >
                      <TrashIcon />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default ProgramBuilder;
