/* eslint-disable @typescript-eslint/no-explicit-any */
// Built-In
import React, { useEffect, useState } from 'react';

// External
import {
  Button,
  Col,
  message,
  Radio,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

// Internal
import InsideHeader from 'components/inside-header/InsideHeader';
import ButtonComponent from 'components/button/Button';
import { router } from 'utils/constants';
import {
  CREATE_MULTI_SECTION,
  GET_EXERCISE,
  GET_PROGRAMS,
  GET_SECTION,
  GET_WORKOUTS,
} from '../api';
import RightIcon from '../icon/RightIcon';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import PaginationCom from 'components/pagination/Pagination';
import TableImage from 'components/table-image/TableImage';

// Interface
interface IExercise {
  data: [];
  limit: number;
  skip: number;
  total: number;
  status: number;
  message: string;
}

interface IDropDown {
  label: string;
  value: string;
}

interface IDataType {
  cost_type: string;
  description: string;
  duration: string;
  fitness_level: string;
  id: string;
  instruction: string;
  thumbnail: any;
  workoutThumbnailId: string;
  workout_cost: number | null;
  workout_equipments: any;
  workout_name: string;
  workout_sets: any;
  workout_training_zones: any;
  workout_visibility: string;
}

interface IResponse {
  data: IDataType[];
  limit: number;
  skip: number;
  total: number;
  status: number;
  message: string;
}

// Variable
const itemType = [
  { label: 'Workout', value: 'workout' },
  { label: 'Exercise', value: 'exercise' },
  { label: 'Program', value: 'program' },
];

const category = [
  {
    id: 1,
    label: 'Beginner',
    value: 'Beginner',
  },
  {
    id: 2,
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    id: 3,
    label: 'Advance',
    value: 'Advance',
  },
  {
    id: 4,
    label: 'Everyone',
    value: 'Everyone',
  },
];

const fitnessLevel = [
  {
    label: 'Beginner',
    value: 'Beginner',
  },
  {
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    label: 'Advance',
    value: 'Advance',
  },
];

const exerciseObject: IExercise = {
  data: [],
  limit: 0,
  skip: 0,
  total: 0,
  status: 0,
  message: '',
};

const workOutObject: IResponse = {
  data: [],
  limit: 0,
  skip: 0,
  total: 0,
  status: 0,
  message: '',
};

const programObject: IResponse = {
  data: [],
  limit: 0,
  skip: 0,
  total: 0,
  status: 0,
  message: '',
};

const { Text } = Typography;

const AddItem = () => {
  // useState
  const [exercise, setExercise] = useState(exerciseObject);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [section, setSection] = useState<IDropDown[]>([]);
  const [sectionFilter, setSectionFilter] = useState<string | null>(null);

  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);

  const [trainingZoneValue, setTrainingZoneValue] = useState<string | null>(
    null,
  );
  const [equipmentValue, setEquipmentValue] = useState<string | null>(null);
  const [categoryValue, setCategoryValue] = useState<string | null>(null);
  const [sectionValue, setSectionValue] = useState<string | null>(null);
  const [statusValue, setStatusValue] = useState<string | null>(null);
  const [itemTypeValue, setItemTypeValue] = useState<string | null>(null);

  const [workoutsData, setWorkoutsData] = useState(workOutObject);
  const [programsData, setProgramsData] = useState(programObject);
  const [fitnessLevelValue, setFitnessLevelValue] = useState<string | null>(
    null,
  );

  const [storeId, setStoreId] = useState<string[]>([]);

  // Variable
  const navigate = useNavigate();
  const exerciseColumns: any = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 130,
      render: (thumbnail: any) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Name',
      dataIndex: 'exercise_name',
      key: 'exercise_name',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: any, record: any) => {
        if (storeId.length > 0) {
          if (storeId.some((item: any) => item === record.id)) {
            return (
              <Text type="success">
                <RightIcon /> Added
              </Text>
            );
          }
          return (
            <div className="g-3">
              <ButtonComponent
                className="border-primary"
                style={{ padding: '12px 16px' }}
                onClick={() => {
                  const temp = [...storeId];
                  temp.push(record?.id);
                  setStoreId(temp);
                }}
              >
                + Add
              </ButtonComponent>
            </div>
          );
        }
        return (
          <div className="g-3">
            <ButtonComponent
              className="border-primary"
              style={{ padding: '12px 16px' }}
              onClick={() => {
                const temp = [...storeId];
                temp.push(record?.id);
                setStoreId(temp);
              }}
            >
              + Add
            </ButtonComponent>
          </div>
        );
      },
    },
  ];

  const workoutColumns: ColumnsType<IDataType> = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 130,
      render: (thumbnail) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Workout Name',
      dataIndex: 'workout_name',
      key: 'workoutName',
      align: 'center',
    },
    {
      title: 'Equipment',
      dataIndex: 'workout_equipments',
      key: 'workout_equipments',
      align: 'center',
      render: (workout_equipments) => {
        const modified = workout_equipments
          ?.map((item: any) => {
            return item?.equipment?.name;
          })
          .join(',');
        return modified;
      },
    },
    {
      title: 'Cost',
      dataIndex: 'workout_cost',
      key: 'workout_cost',
      align: 'center',
      width: 100,
    },
    {
      title: 'Fitness Level',
      dataIndex: 'fitness_level',
      key: 'fitnessLevel',
      align: 'center',
      width: 170,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: any, record: any) => {
        if (storeId.length > 0) {
          if (storeId.some((item: any) => item === record.id)) {
            return (
              <Text type="success">
                <RightIcon /> Added
              </Text>
            );
          }
          return (
            <div className="g-3">
              <ButtonComponent
                className="border-primary"
                style={{ padding: '12px 16px' }}
                onClick={() => {
                  const temp = [...storeId];
                  temp.push(record?.id);
                  setStoreId(temp);
                }}
              >
                + Add
              </ButtonComponent>
            </div>
          );
        }
        return (
          <div className="g-3">
            <ButtonComponent
              className="border-primary"
              style={{ padding: '12px 16px' }}
              onClick={() => {
                const temp = [...storeId];
                temp.push(record?.id);
                setStoreId(temp);
              }}
            >
              + Add
            </ButtonComponent>
          </div>
        );
      },
    },
  ];

  const programColumns: any = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'media_item',
      align: 'center',
      width: 130,
      render: (thumbnail: any) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Program Name',
      dataIndex: 'program_name',
      key: 'name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Cost',
      dataIndex: 'program_cost',
      key: 'cost',
      align: 'center',
      width: 150,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: 'Fitness Level',
      dataIndex: 'fitness_level',
      key: 'fitness_level',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 200,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: any, record: any) => {
        if (storeId.length > 0) {
          if (storeId.some((item: any) => item === record.id)) {
            return (
              <Text type="success">
                <RightIcon /> Added
              </Text>
            );
          }
          return (
            <div className="g-3">
              <ButtonComponent
                className="border-primary"
                style={{ padding: '12px 16px' }}
                onClick={() => {
                  const temp = [...storeId];
                  temp.push(record?.id);
                  setStoreId(temp);
                }}
              >
                + Add
              </ButtonComponent>
            </div>
          );
        }
        return (
          <div className="g-3">
            <ButtonComponent
              className="border-primary"
              style={{ padding: '12px 16px' }}
              onClick={() => {
                const temp = [...storeId];
                temp.push(record?.id);
                setStoreId(temp);
              }}
            >
              + Add
            </ButtonComponent>
          </div>
        );
      },
    },
  ];

  // Function
  const fetchExercise = async (
    searchValue: string | null,
    pageP: number,
    pageSizeP: number,
    status: string | null,
    trainingZoneId: string | null,
    equipmentId: string | null,
    categoryId: string | null,
    sectionId: string | null,
  ) => {
    setExercise(exerciseObject);
    setIsLoading(true);
    try {
      const res = await GET_EXERCISE(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        searchValue,
        status,
        trainingZoneId,
        equipmentId,
        categoryId,
        sectionId,
      );
      if (res.status === 1 || res.status === '1') {
        setExercise(res);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSection = async () => {
    try {
      const res = await GET_SECTION();
      if (res?.status === 1 || res?.status === '1') {
        const tempOption = res?.data?.map((item: any) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setSection(tempOption);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: any) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: any) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const fetchWorkouts = async (
    pageP: number,
    pageSizeP: number,
    fitness: string | null,
    training: string | null,
    equ: string | null,
  ) => {
    setIsLoading(true);
    setWorkoutsData(workOutObject);
    try {
      const res = await GET_WORKOUTS(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        fitness,
        training,
        equ,
      );
      if (res?.status === 1 || res?.status === '1') {
        setWorkoutsData(res);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(`${error || 'Something went wrong !'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrograms = async (
    pageP: number,
    pageSizeP: number,
    fitness: string | null,
    training: string | null,
    equ: string | null,
  ) => {
    setIsLoading(true);
    setProgramsData(programObject);
    try {
      const res = await GET_PROGRAMS(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        fitness,
        training,
        equ,
      );
      if (res?.status === 1 || res?.status === '1') {
        setProgramsData(res);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(`${error || 'Something went wrong !'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const clearAllFilter = () => {
    setTrainingZoneValue(null);
    setEquipmentValue(null);
    setCategoryValue(null);
    setSectionFilter(null);
    setStatusValue(null);
    setFitnessLevelValue(null);
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setPage(pageT);
    setPageSize(pageSizeT);
  };

  const handleAddItem = async () => {
    const payload = storeId.map((item: string) => {
      return {
        sectionId: sectionValue,
        item_type: itemTypeValue,
        [itemTypeValue === 'exercise'
          ? 'exerciseId'
          : itemTypeValue === 'workout'
          ? 'workoutId'
          : 'programId']: item,
      };
    });
    try {
      const res = await CREATE_MULTI_SECTION(payload);
      if (res?.status === 1 || res?.status === '1') {
        navigate(-1);
        message.success(res?.message);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleChangeOfItemType = (e: string) => {
    clearAllFilter();
    setStoreId([]);
    setItemTypeValue(e);
  };

  // useEffect
  useEffect(() => {
    if (itemTypeValue === 'exercise') {
      fetchExercise(
        null,
        page,
        pageSize,
        statusValue,
        trainingZoneValue,
        equipmentValue,
        categoryValue,
        sectionFilter,
      );
    }
  }, [
    categoryValue,
    equipmentValue,
    itemTypeValue,
    page,
    pageSize,
    sectionFilter,
    statusValue,
    trainingZoneValue,
  ]);

  useEffect(() => {
    if (itemTypeValue === 'workout') {
      fetchWorkouts(
        page,
        pageSize,
        fitnessLevelValue,
        trainingZoneValue,
        equipmentValue,
      );
    }
  }, [
    equipmentValue,
    fitnessLevelValue,
    itemTypeValue,
    page,
    pageSize,
    trainingZoneValue,
  ]);

  useEffect(() => {
    if (itemTypeValue === 'program') {
      fetchPrograms(
        page,
        pageSize,
        fitnessLevelValue,
        trainingZoneValue,
        equipmentValue,
      );
    }
  }, [
    equipmentValue,
    fitnessLevelValue,
    itemTypeValue,
    page,
    pageSize,
    trainingZoneValue,
  ]);

  useEffect(() => {
    fetchSection();
    fetchTrainingZone();
    fetchEquipment();
  }, []);

  return (
    <>
      <InsideHeader>
        <div className="d-flex align-items-center mb-3 w-100">
          <p className="font-size-18 line-height-26 mb-0 width-150">Section:</p>
          <Select
            placeholder="Select Section"
            className="font-size-18 line-height-26 w-100"
            options={section}
            onChange={(e: string) => setSectionValue(e)}
            value={sectionValue}
          />
        </div>
        <div className="d-flex align-items-center mt-3">
          <p className="font-size-18 line-height-26 mb-0  width-150">
            Item Type:
          </p>
          <Select
            placeholder="Select Item Type"
            className="font-size-18 line-height-26 w-100"
            options={itemType}
            onChange={handleChangeOfItemType}
            value={itemTypeValue}
          />
        </div>
      </InsideHeader>

      {itemTypeValue === 'exercise' && sectionValue && (
        <InsideHeader className="mt-3 px-24-30">
          <p className="font-size-18 line-height-26">
            Search Exercise And Add:
          </p>
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Category"
                className="font-size-18 line-height-26 w-100"
                options={category}
                onChange={(e) => setCategoryValue(e)}
                value={categoryValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Section"
                className="font-size-18 line-height-26  w-100"
                options={section}
                onChange={(e) => setSectionFilter(e)}
                value={sectionFilter}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center g-3 gutter-row mb-1"
            >
              <div className="w-100">
                <span className="font-size-18 mb-0 line-height-26 mr-1">
                  Status
                </span>
                <Radio.Group
                  onChange={(e) => setStatusValue(e.target.value)}
                  value={statusValue}
                >
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">InActive</Radio>
                </Radio.Group>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              <Button type="link" onClick={clearAllFilter}>
                Clear All
              </Button>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {itemTypeValue === 'workout' && sectionValue && (
        <InsideHeader className="mt-3 px-24-30">
          <p className="font-size-18 line-height-26">Search Workout And Add:</p>
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Fitness Level"
                className="font-size-18 line-height-26 w-100"
                options={fitnessLevel}
                onChange={(e) => setFitnessLevelValue(e)}
                value={fitnessLevelValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              <Button type="link" onClick={clearAllFilter}>
                Clear All
              </Button>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {itemTypeValue === 'program' && sectionValue && (
        <InsideHeader className="mt-3 px-24-30">
          <p className="font-size-18 line-height-26">Search Program And Add:</p>
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Fitness Level"
                className="font-size-18 line-height-26 w-100"
                options={fitnessLevel}
                onChange={(e) => setFitnessLevelValue(e)}
                value={fitnessLevelValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              <Button type="link" onClick={clearAllFilter}>
                Clear All
              </Button>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {(itemTypeValue === 'exercise' ||
        itemTypeValue === 'workout' ||
        itemTypeValue === 'program') &&
        sectionValue && (
          <div className="w-100 mt-3 box-shadow-3 border-radius-5">
            <Table
              className="w-100 overflow-auto"
              rowKey="id"
              columns={
                itemTypeValue === 'exercise'
                  ? exerciseColumns
                  : itemTypeValue === 'workout'
                  ? workoutColumns
                  : itemTypeValue === 'program'
                  ? programColumns
                  : null
              }
              dataSource={
                itemTypeValue === 'exercise'
                  ? exercise.data
                  : itemTypeValue === 'workout'
                  ? workoutsData.data
                  : itemTypeValue === 'program'
                  ? programsData.data
                  : undefined
              }
              pagination={false}
              loading={isLoading}
              scroll={{ x: 900 }}
            />
            <div className="bg-white w-100 p-2 d-flex justify-content-between align-items-center">
              <p className="mb-0">
                {exercise.data.length} of {exercise?.total} Results
              </p>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={page}
                showLessItems={true}
                total={
                  itemTypeValue === 'exercise'
                    ? exercise.total
                    : workoutsData.total
                }
              />
            </div>
          </div>
        )}

      <div className="form-btn-group d-flex justify-content-center mt-3">
        <ButtonComponent
          className="mr-2 width-150 btn-cancel btn-component"
          style={{ background: 'transparent', color: '#8C8673' }}
          onClick={() => navigate(router.section)}
        >
          Cancel
        </ButtonComponent>
        {storeId.length > 0 && (
          <ButtonComponent
            htmlType="submit"
            type="primary"
            className="btn-save btn-all btn-component width-150"
            onClick={handleAddItem}
          >
            Add Item
          </ButtonComponent>
        )}
      </div>
    </>
  );
};

export default AddItem;
