import React from 'react';

const FileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0023 21V15M21 17L19 15L17 17.0002M13 3H9.8C8.11984 3 7.27976 3 6.63803 3.32698C6.07354 3.6146 5.6146 4.07354 5.32698 4.63803C5 5.27976 5 6.11984 5 7.8V16.2C5 17.8802 5 18.7202 5.32698 19.362C5.6146 19.9265 6.07354 20.3854 6.63803 20.673C7.27976 21 8.11984 21 9.8 21H15M13 3L19 9M13 3V5.8C13 6.9201 13 7.48016 13.218 7.90798C13.4097 8.28431 13.7157 8.59027 14.092 8.78201C14.5198 9 15.0799 9 16.2 9H19M19 9V11"
        stroke="#8C8673"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
