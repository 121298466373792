/* eslint-disable @typescript-eslint/no-explicit-any */
// Built-In
import React, { useEffect, useState } from 'react';

// External
import { useNavigate, useParams } from 'react-router-dom';
import { Input, message, Table, Tooltip } from 'antd';

// Internal
import InsideHeader from '../../components/inside-header/InsideHeader';
import ButtonComponent from '../../components/button/Button';
import TableImage from '../../components/table-image/TableImage';
import CustomModel from '../../components/model/CustomModel';
import { router } from '../../utils/constants';
import {
  DeleteIcon,
  EditIcon,
  InfoIcon,
  SearchIcon,
} from '../../icon/CommonIcon';
import ExerciseInfoDrawer from './components/ExerciseInfoDrawer';
import WorkOutInfoDrawer from './components/WorkOutInfoDrawer';
import EditItemModel from './components/EditItemModel';
import {
  DELETE_SECTION_ITEM,
  GET_SECTION_ITEM,
  UPDATE_SECTION_ITEM,
} from './api';
import PaginationCom from 'components/pagination/Pagination';

const SeeAllSection = () => {
  // useState
  const [sectionItem, setSectionItem] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string | null>(null);
  const [tempSearch, setTempSearch] = useState<string | null>(null);

  const [isShowDeleteModel, setIsShowDeleteModel] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string | null>(null);

  const [infoSliderVisible, setInfoSliderVisible] = useState<boolean>(false);
  const [singleRecordInfo, setSingleRecordInfo] = useState<any>();

  const [isShowEditModel, setIsShowEditModel] = useState<boolean>(false);
  const [sectionValue, setSectionValue] = useState<string | null>(null);

  // Variable
  const navigate = useNavigate();
  const { id = null } = useParams();

  // Function
  const fetchAllSectionItem = async (
    sectionId: string,
    page: number,
    pageSizeP: number,
    searchValue: string | null,
  ) => {
    setIsLoading(true);
    try {
      const res = await GET_SECTION_ITEM(
        sectionId,
        pageSizeP,
        (page - 1) * pageSizeP,
        searchValue,
      );
      if (res?.status === 1 || res?.status === '1') {
        setSectionItem(res);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(`${error || 'Something went wrong !'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setCurrentPage(pageT);
    setPageSize(pageSizeT);
  };

  const handleDelete = (sectionItemId: string) => {
    setIsShowDeleteModel(true);
    setRecordId(sectionItemId);
  };

  const handleCancelDeleteModal = () => {
    setRecordId(null);
    setIsShowDeleteModel(false);
  };

  const deleteSectionItem = async () => {
    try {
      const res = await DELETE_SECTION_ITEM(recordId);
      if (res?.status === 1 || res?.status === '1') {
        message.success(res.message);
        handleCancelDeleteModal();
        let page = currentPage;
        if (sectionItem?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page -= 1;
        } else if (id) {
          fetchAllSectionItem(id, page, pageSize, search);
        }
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleShowInfo = (item: any) => {
    setInfoSliderVisible(true);
    setSingleRecordInfo(item);
  };

  const handleCancelEditModel = () => {
    setIsShowEditModel(false);
    setSingleRecordInfo(null);
    setSectionValue(null);
  };

  const handleShowEdit = (item: any) => {
    setIsShowEditModel(true);
    setSingleRecordInfo(item);
  };

  const handleSave = async () => {
    const payload = {
      sectionId: sectionValue,
    };
    try {
      const res = await UPDATE_SECTION_ITEM(singleRecordInfo?.id, payload);
      if (res?.status === 1 || res?.status === '1') {
        handleCancelEditModel();
        if (id) {
          fetchAllSectionItem(id, currentPage, pageSize, search);
        }
        message.success(res?.message);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      setSearch(tempSearch);
    }
  };

  // useEffect
  useEffect(() => {
    if (id) {
      fetchAllSectionItem(id, currentPage, pageSize, search);
    }
  }, [currentPage, id, pageSize, search]);

  const columns: any = [
    {
      title: 'Image',
      dataIndex: 'exercise',
      align: 'center',
      width: 130,
      render: (image: any, record: any) => {
        if (image) {
          return <TableImage image={image?.thumbnail?.media} />;
        }
        return <TableImage image={record?.workout?.thumbnail?.media} />;
      },
    },
    {
      title: 'Workout Name / Exercise Name',
      dataIndex: 'exercise',
      key: 'name',
      align: 'center',
      render: (exercise: any, record: any) => {
        if (exercise) {
          return exercise?.exercise_name;
        }
        return record?.workout?.workout_name;
      },
    },
    {
      title: 'Description',
      dataIndex: 'exercise',
      align: 'center',
      render: (exercise: any, record: any) => {
        if (exercise) {
          return exercise?.description;
        }
        return record?.workout?.description;
      },
    },
    {
      title: 'Type',
      dataIndex: 'item_type',
      align: 'center',
      width: 100,
      render: (item_type: any) => {
        if (item_type === 'workout') {
          return 'Workout';
        }
        if (item_type === 'exercise') {
          return 'Exercise';
        }
        return '-';
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 170,
      render: (_: any, record: any) => {
        return (
          <div className="g-3">
            <Tooltip title="Edit">
              <EditIcon
                className="font-size-20 edit-btn-hover cursor-pointer mx-1"
                onClick={() => handleShowEdit(record)}
              />
            </Tooltip>

            <Tooltip title="View Details">
              <InfoIcon
                className="font-size-20 info-btn-hover cursor-pointer mx-1"
                onClick={() => handleShowInfo(record)}
              />
            </Tooltip>

            <Tooltip title="Delete">
              <DeleteIcon
                className="font-size-20 delete-btn-hover cursor-pointer mx-1"
                onClick={() => handleDelete(record?.id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search exercise/workout by names..."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearch(tempSearch)}
              />
            </Tooltip>
          }
          className="input-control"
          type="search"
          allowClear
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            type="primary"
            className="mr-1 width-180"
            onClick={() => navigate(router.sectionItemAdd)}
          >
            + Add New Item
          </ButtonComponent>
        </div>
      </InsideHeader>

      {/* Data Table */}
      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        <Table
          columns={columns}
          dataSource={sectionItem?.data}
          pagination={false}
          loading={isLoading}
          rowKey="id"
          className="w-100 overflow-auto"
        />

        <div className="bg-white p-2 w-100 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {sectionItem?.data?.length} of {sectionItem?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={currentPage}
            showLessItems={true}
            total={sectionItem?.total}
          />
        </div>
      </div>

      <div className="form-btn-group d-flex justify-content-center mt-3">
        <ButtonComponent
          className="mr-2 width-150 btn-cancel btn-component"
          style={{ background: 'transparent', color: '#8C8673' }}
          onClick={() => navigate(router.section)}
        >
          Back
        </ButtonComponent>
      </div>

      {/* Delete Modal */}
      {isShowDeleteModel && (
        <CustomModel
          open={isShowDeleteModel}
          handleOk={deleteSectionItem}
          handleCancel={handleCancelDeleteModal}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}

      {/* Info Drawer of Exercise */}
      {infoSliderVisible && singleRecordInfo.item_type === 'exercise' && (
        <ExerciseInfoDrawer
          infoSliderVisible={infoSliderVisible}
          setInfoSliderVisible={setInfoSliderVisible}
          data={singleRecordInfo}
        />
      )}

      {/* Info Drawer of Workout */}
      {infoSliderVisible && singleRecordInfo.item_type === 'workout' && (
        <WorkOutInfoDrawer
          infoSliderVisible={infoSliderVisible}
          setInfoSliderVisible={setInfoSliderVisible}
          data={singleRecordInfo}
        />
      )}

      {/* Edit Drawer  */}
      {isShowEditModel && (
        <EditItemModel
          handleOk={handleSave}
          open={isShowEditModel}
          handleCancel={handleCancelEditModel}
          data={singleRecordInfo}
          sectionValue={sectionValue}
          setSectionValue={setSectionValue}
        />
      )}
    </>
  );
};

export default SeeAllSection;
