/* eslint-disable @typescript-eslint/no-explicit-any */
// Built in
import React from 'react';
// External

// internal
import ButtonComponent from 'components/button/Button';
import useStepOneHook from 'features/program/hooks/useStepOneHook';
import AddWeekDrawer from './AddWeekDrawer';
import ProgramBuilder from 'features/program/components/ProgramBuilder';

interface Props {
  onNext: () => void;
  onBack: () => void;
  programBuilder: any;
  setProgramBuilder: any;
}

const Step1 = ({
  onNext,
  onBack,
  programBuilder,
  setProgramBuilder,
}: Props) => {
  const {
    setWeekSliderVisible,
    weekSliderVisible,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setFitnessLevelValue,
    fitnessLevelValue,
    clearAllFilter,
    handleChangeOfSearch,
    tempSearch,
    setIsShowFilter,
    isShowFilter,
    isLoading,
    workoutsData,
    columns,
    onChangePage,
    page,
    handleSearch,
    setSearch,
    mainIndex,
    setMainIndex,
    editData,
    setEditData,
    handleChangeOfName,
    programName,
    setProgramName,
    isAdd,
    setIsAdd,
  } = useStepOneHook({
    programBuilder,
    setProgramBuilder,
  });

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <ButtonComponent
          type="primary"
          className="mr-1 width-180"
          onClick={() => {
            setProgramName('');
            setEditData(null);
            // setProgramBuilder(null);
            const temp = [...programBuilder];
            temp.push({
              week_name: '',
              week_no: temp.length + 1,
              temp_id: temp.length + 1,
              program_week_workouts: [],
            });
            setIsAdd(true);
            setProgramBuilder(temp);
            setWeekSliderVisible(true);
          }}
        >
          + Add Week
        </ButtonComponent>
      </div>

      <ProgramBuilder
        programBuilder={programBuilder}
        setProgramBuilder={setProgramBuilder}
        setWeekSliderVisible={setWeekSliderVisible}
        setMainIndex={setMainIndex}
        setEditData={setEditData}
        setProgramName={setProgramName}
        setIsAdd={setIsAdd}
      />

      <div className="form-btn-group mt-3">
        <ButtonComponent
          className="mr-2 width-150 btn-cancel btn-component"
          style={{ background: 'transparent', color: '#8C8673' }}
          onClick={onBack}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          htmlType="submit"
          type="primary"
          className="btn-save btn-all btn-component"
          onClick={onNext}
        >
          Next
        </ButtonComponent>
      </div>

      {/* Info Drawer */}
      {weekSliderVisible && (
        <AddWeekDrawer
          isAdd={isAdd}
          handleChangeOfName={handleChangeOfName}
          programName={programName}
          mainIndex={mainIndex}
          editData={editData}
          setProgramBuilder={setProgramBuilder}
          programBuilder={programBuilder}
          weekSliderVisible={weekSliderVisible}
          setWeekSliderVisible={setWeekSliderVisible}
          trainingZone={trainingZone}
          setTrainingZoneValue={setTrainingZoneValue}
          trainingZoneValue={trainingZoneValue}
          equipment={equipment}
          setEquipmentValue={setEquipmentValue}
          equipmentValue={equipmentValue}
          setFitnessLevelValue={setFitnessLevelValue}
          fitnessLevelValue={fitnessLevelValue}
          clearAllFilter={clearAllFilter}
          handleChangeOfSearch={handleChangeOfSearch}
          tempSearch={tempSearch}
          setIsShowFilter={setIsShowFilter}
          isShowFilter={isShowFilter}
          isLoading={isLoading}
          workoutsData={workoutsData}
          columns={columns}
          onChangePage={onChangePage}
          page={page}
          handleSearch={handleSearch}
          setSearch={setSearch}
          setProgramName={setProgramName}
        />
      )}
    </>
  );
};

export default Step1;
