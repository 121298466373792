/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Input } from 'antd';
import React from 'react';

const CustomInput = ({ ...otherProps }: any) => {
  return (
    <Input
      {...otherProps}
      className="input-control w-100 line-height-22 font-size-16 text border-radius-8"
      allowClear
    />
  );
};

export default CustomInput;
