/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext<any | null>(null);

const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};

const getUser = () => {
  const userData = localStorage.getItem('user-data');
  return userData ? JSON.parse(userData) : null;
};

interface IProps {
  children: React.ReactNode;
}

const AuthContextProvider = ({ children }: IProps) => {
  const [userData, setUserData] = useState(getUser());
  const [token, setToken] = useState(getToken());
  const values = React.useMemo(
    () => ({
      userData,
      setUserData,
      token,
      setToken,
    }),
    [userData, token],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
