/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Pagination } from 'antd';
import { LeftIcon, RightIcon } from 'icon/CommonIcon';

const PaginationCom = (props: any) => {
  // pagination
  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return <LeftIcon />;
    }
    if (type === 'next') {
      return <RightIcon />;
    }
    return originalElement;
  };
  return <Pagination {...props} itemRender={itemRender} />;
};

export default PaginationCom;
