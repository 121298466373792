/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Col, Drawer, Row, Tag } from 'antd';
import Logo from '../../../assets/images/logo.png';

interface IProps {
  setInfoSliderVisible: (open: boolean) => void;
  infoSliderVisible: boolean;
  data: any;
}

const WorkOutInfoDrawer = ({
  setInfoSliderVisible,
  infoSliderVisible,
  data,
}: IProps) => {
  return (
    <Drawer
      title="Workout Info"
      placement="right"
      onClose={() => setInfoSliderVisible(false)}
      open={infoSliderVisible}
      className="info-drawer"
    >
      <div className="text-center">
        {data?.workout?.thumbnail?.media ? (
          <img
            src={data?.workout?.thumbnail?.media}
            alt=""
            width="150px"
            height="150px"
            className="object-fit-contain"
            crossOrigin="anonymous"
          />
        ) : (
          <img
            src={Logo}
            alt=""
            width="150px"
            height="150px"
            className="object-fit-contain"
            crossOrigin="anonymous"
          />
        )}
      </div>

      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Workout Name
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row">
          :
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className="gutter-row">
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.workout?.workout_name}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Training Zone
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          {data?.workout?.workout_training_zones?.map((record: any) => {
            return (
              <Tag
                key={record.id}
                color="#F8F7F6"
                className="border-radius-8"
                style={{ border: '1px solid #8C8673' }}
              >
                <h2 className="font-size-18 line-height-26 mb-0 text-grey">
                  {record?.training_zone?.name}
                </h2>
              </Tag>
            );
          })}
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Equipment
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          {data?.workout?.workout_equipments?.map((record: any) => {
            return (
              <Tag
                key={record.id}
                color="#F8F7F6"
                className="border-radius-8"
                style={{ border: '1px solid #8C8673' }}
              >
                <h2 className="font-size-18 line-height-26 mb-0 text-grey">
                  {record?.equipment?.name}
                </h2>
              </Tag>
            );
          })}
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Cost Type
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.workout?.cost_type}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Workout Cost
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.workout?.workout_cost || '-'}
          </h2>
        </Col>
      </Row>

      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Workout Description
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.workout?.description}
          </h2>
        </Col>
      </Row>

      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Workout Duration
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.workout?.duration}
          </h2>
        </Col>
      </Row>

      <Row gutter={0} className="mt-2 mb-1">
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className="gutter-row mb-25">
          <h2 className="font-size-18 line-height-26 font-weight-600 mb-0">
            Fitness Level
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className="gutter-row mb-25">
          :
        </Col>
        <Col
          xs={22}
          sm={16}
          md={16}
          lg={14}
          xl={14}
          className="gutter-row mb-25"
        >
          <h2 className="font-size-18 line-height-26 mb-0">
            {data?.workout?.fitness_level}
          </h2>
        </Col>
      </Row>
    </Drawer>
  );
};

export default WorkOutInfoDrawer;
