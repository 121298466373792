import React from 'react';

const ResetIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.53738 17.0137V16.2637C5.12954 16.2637 4.79646 16.5896 4.78756 16.9974L5.53738 17.0137ZM18.5307 6.99968L18.5307 7.74968C18.9449 7.74968 19.2807 7.41389 19.2807 6.99968H18.5307ZM20.9059 11.0156C20.8607 10.6038 20.4902 10.3068 20.0785 10.352C19.6667 10.3973 19.3697 10.7678 19.4149 11.1795L20.9059 11.0156ZM3.09625 12.9825C3.14145 13.3942 3.51187 13.6914 3.92361 13.6462C4.33535 13.601 4.63249 13.2305 4.58729 12.8188L3.09625 12.9825ZM19.2807 4.01861C19.2807 3.60439 18.9449 3.26861 18.5307 3.26861C18.1165 3.26861 17.7807 3.60439 17.7807 4.01861H19.2807ZM15.5002 6.24975C15.086 6.24976 14.7502 6.58556 14.7502 6.99977C14.7503 7.41398 15.086 7.74976 15.5003 7.74975L15.5002 6.24975ZM4.72277 19.9644C4.71373 20.3785 5.0421 20.7215 5.45622 20.7306C5.87033 20.7396 6.21337 20.4112 6.22241 19.9971L4.72277 19.9644ZM8.36743 17.7637C8.78165 17.7637 9.11743 17.428 9.11743 17.0137C9.11743 16.5995 8.78165 16.2637 8.36743 16.2637V17.7637ZM17.2753 17.2738C14.3871 20.1619 9.29058 20.1904 6.10356 16.5219L4.9712 17.5056C8.70991 21.8091 14.8127 21.8577 18.3359 18.3344L17.2753 17.2738ZM6.72708 6.72561C8.1553 5.29739 10.3883 4.55017 12.5847 4.64468C14.7776 4.73903 16.7973 5.66252 17.8969 7.40065L19.1645 6.59872C17.7337 4.3369 15.1892 3.25535 12.6492 3.14606C10.1127 3.03693 7.44389 3.88749 5.66642 5.66495L6.72708 6.72561ZM19.4149 11.1795C19.6539 13.3533 18.9402 15.6088 17.2753 17.2738L18.3359 18.3344C20.3363 16.3341 21.1925 13.6223 20.9059 11.0156L19.4149 11.1795ZM4.58729 12.8188C4.34869 10.6453 5.06238 8.39031 6.72708 6.72561L5.66642 5.66495C3.66642 7.66495 2.81013 10.3762 3.09625 12.9825L4.58729 12.8188ZM17.7807 4.01861V6.99968H19.2807V4.01861H17.7807ZM18.5307 6.24968L15.5002 6.24975L15.5003 7.74975L18.5307 7.74968L18.5307 6.24968ZM6.22241 19.9971L6.2872 17.0301L4.78756 16.9974L4.72277 19.9644L6.22241 19.9971ZM5.53738 17.7637H8.36743V16.2637H5.53738V17.7637Z"
        fill="#8C8673"
      />
    </svg>
  );
};

export default ResetIcon;
