/* eslint-disable @typescript-eslint/no-explicit-any */
// Built in
import React from 'react';

// External
import {
  Button,
  Col,
  Drawer,
  Input,
  message,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';

// Internal
import ButtonComponent from 'components/button/Button';
import InsideHeader from 'components/inside-header/InsideHeader';
import PaginationCom from 'components/pagination/Pagination';
import { FilterIcon, SearchIcon } from 'icon/CommonIcon';
import { IProgramWeeks } from 'features/program/types';
import CustomInput from 'components/input/CustomInput';

interface AddWeekDrawerProps {
  programBuilder: IProgramWeeks[];
  setWeekSliderVisible: (open: boolean) => void;
  weekSliderVisible: boolean;
  setProgramBuilder: (value: any) => void;
  trainingZone: any;
  setTrainingZoneValue: any;
  trainingZoneValue: any;
  equipment: any;
  setEquipmentValue: any;
  equipmentValue: any;
  setFitnessLevelValue: any;
  fitnessLevelValue: any;
  clearAllFilter: any;
  handleChangeOfSearch: any;
  tempSearch: any;
  setIsShowFilter: any;
  isShowFilter: any;
  isLoading: any;
  workoutsData: any;
  columns: any;
  onChangePage: any;
  page: any;
  handleSearch: any;
  setSearch: any;
  editData: any;
  mainIndex: any;
  handleChangeOfName: any;
  programName: any;
  setProgramName: any;
  isAdd: boolean;
}

// Variable
const fitnessLevel = [
  {
    label: 'Beginner',
    value: 'Beginner',
  },
  {
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    label: 'Advance',
    value: 'Advance',
  },
];

const AddWeekDrawer = (props: AddWeekDrawerProps) => {
  const {
    weekSliderVisible,
    setWeekSliderVisible,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    setEquipmentValue,
    equipmentValue,
    setFitnessLevelValue,
    fitnessLevelValue,
    clearAllFilter,
    handleChangeOfSearch,
    tempSearch,
    setIsShowFilter,
    isShowFilter,
    isLoading,
    workoutsData,
    columns,
    onChangePage,
    page,
    handleSearch,
    setSearch,
    handleChangeOfName,
    programName,
    programBuilder,
    setProgramBuilder,
    isAdd,
  } = props;

  const handleClose = () => {
    const temp = [...programBuilder];
    if (isAdd) {
      temp?.splice(programBuilder?.length - 1, 1);
      setProgramBuilder(temp);
    }
    setWeekSliderVisible(false);
  };

  return (
    <Drawer
      title="Week"
      placement="right"
      size="large"
      onClose={() => handleClose()}
      open={weekSliderVisible}
      className="add-week-drawer"
    >
      <InsideHeader className="mb-3 border-radius-8 ">
        <CustomInput
          type="text"
          placeholder="Enter Week Name"
          onChange={handleChangeOfName}
          value={programName}
          name="programName"
        />
      </InsideHeader>

      <InsideHeader className="search-section main-search">
        <Input
          placeholder="Search Workouts by names..."
          suffix={
            <Tooltip title="Search Now">
              <SearchIcon
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearch(tempSearch)}
              />
            </Tooltip>
          }
          className="input-control"
          type="search"
          onChange={handleChangeOfSearch}
          onKeyPress={(e) => {
            if (e?.key === 'Enter') {
              handleSearch();
            }
          }}
          allowClear
        />
        <div className="search-section__btn-group btnCount-3">
          <ButtonComponent
            className="btn-inside-header mr-1 mb-1 border-radius-8 text-black btn-component d-flex align-items-center justify-content-center gap-10"
            onClick={() => setIsShowFilter(!isShowFilter)}
          >
            <FilterIcon style={{ color: '#2B2923' }} /> Workout Filter
          </ButtonComponent>
        </div>
      </InsideHeader>

      {isShowFilter && (
        <InsideHeader className="mt-3 px-24-30">
          <Row gutter={20} className="align-items-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select TrainingZone"
                className="font-size-18 line-height-26 w-100"
                options={trainingZone}
                onChange={(e) => setTrainingZoneValue(e)}
                value={trainingZoneValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Equipment"
                className="font-size-18 line-height-26 w-100"
                options={equipment}
                onChange={(e) => setEquipmentValue(e)}
                value={equipmentValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="gutter-row mb-1"
            >
              <Select
                placeholder="Select Fitness Level"
                className="font-size-18 line-height-26 w-100"
                options={fitnessLevel}
                onChange={(e) => setFitnessLevelValue(e)}
                value={fitnessLevelValue}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="d-flex align-items-center gutter-row mb-1"
            >
              <Button type="link" onClick={clearAllFilter}>
                Clear All
              </Button>
            </Col>
          </Row>
        </InsideHeader>
      )}

      <div className="w-100 mt-3 box-shadow-3 border-radius-5">
        <Table
          className="w-100 overflow-auto"
          rowKey="id"
          columns={columns}
          dataSource={workoutsData?.data}
          pagination={false}
          loading={isLoading}
          scroll={{ x: 900 }}
        />

        <div className="bg-white w-100 p-2 d-flex justify-content-between align-items-center">
          <p className="mb-0">
            {workoutsData?.data?.length} of {workoutsData?.total} Results
          </p>
          <PaginationCom
            onChange={onChangePage}
            showSizeChanger={true}
            current={page}
            showLessItems={true}
            total={workoutsData?.total}
          />
        </div>
      </div>

      <div className="w-100 d-flex justify-center mt-3">
        <ButtonComponent
          type="primary"
          onClick={() => {
            if (
              programBuilder[programBuilder?.length - 1]?.week_name === '' ||
              null
            ) {
              message.error('Enter Program Name');
            } else if (
              programBuilder[programBuilder?.length - 1]?.program_week_workouts
                .length === 0
            ) {
              message.error('Enter Program Workouts');
            } else {
              setWeekSliderVisible(false);
            }
          }}
        >
          Done
        </ButtonComponent>
      </div>
    </Drawer>
  );
};

export default AddWeekDrawer;
