import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import AdminLayout from '../layout/AdminLayout';

const PrivateRoute = () => {
  const { token } = useAuth();
  return token ? (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
