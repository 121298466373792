// Build-In
import React from 'react';

// External
import { Route } from 'react-router-dom';

// Internal
import { router } from 'utils/constants';
import Login from '../Login';

const AppRoutes = () => {
  return <Route path={router.login} element={<Login />} />;
};

export default AppRoutes;
