import React from 'react';

import { Button, Col, Form, Input, message, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import InsideHeader from 'components/inside-header/InsideHeader';
import { useAuth } from 'context/AuthContext';
import { UPDATE_ADMIN_USER } from './api';

interface FormValue {
  newPassword: string;
  conformPassword: string;
  oldPassword: string;
}

const ChangePassword = () => {
  const { userData } = useAuth();

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (value: FormValue) => {
    if (value?.newPassword !== value?.conformPassword) {
      return message.error('Conform password is not match');
    }

    try {
      const res = await UPDATE_ADMIN_USER(userData?.id, {
        old_password: value?.oldPassword,
        password: value?.newPassword,
      });
      if (res?.status === 1 || res?.status === '1') {
        message.success('Password change successfully !');
        form.resetFields();
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  return (
    <>
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
          lg: { span: 16 },
        }}
        labelAlign="left"
        name="control-hooks"
        className="form-style"
        form={form}
        onFinish={onFinish}
      >
        <Row justify="center" gutter={0} className="mt-4">
          <Col xs={24} sm={24} md={20} lg={15} xl={15} className="gutter-row">
            <InsideHeader>
              <div className="d-flex justify-content-between">
                <h1 className="font-size-28">Change Password</h1>
              </div>
              <Form.Item
                name="oldPassword"
                label="Old Password"
                rules={[
                  { required: true, message: 'Old password is required' },
                ]}
              >
                <Input.Password
                  className="input-control"
                  placeholder="Old Password"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  { required: true, message: 'New password is required' },
                ]}
              >
                <Input.Password
                  className="input-control"
                  placeholder="New Password"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="conformPassword"
                label="Conform Password"
                rules={[
                  { required: true, message: 'Conform password is required' },
                ]}
              >
                <Input.Password
                  className="input-control"
                  placeholder="Conform Password"
                  allowClear
                />
              </Form.Item>
              <div className="form-btn-group mt-4">
                <Button
                  size="large"
                  className="mr-2 width-180"
                  onClick={() => navigate('/profile')}
                >
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className="width-180"
                >
                  Change
                </Button>
              </div>
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChangePassword;
