import React from 'react';

import { Button } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ButtonComponent = ({ className, ...otherProps }: any) => {
  return (
    <Button
      {...otherProps}
      style={{ height: '51px', fontSize: '18px', lineHeight: '26px' }}
      className={`${className} btn-component`}
    />
  );
};

export default ButtonComponent;
