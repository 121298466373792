// Built-In
import { useEffect, useState } from 'react';

// External
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';

// Internal
import { router } from 'utils/constants';
import { GET_WORKOUT_BY_ID } from '../api';
import { IWorkoutDetails } from '../types';

const useAddEditHook = () => {
  // useState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [workOutBuilder, setWorkOutBuilder] = useState<any>([]);
  const [workOutDetails, setWorkOutDetails] = useState<IWorkoutDetails | null>(
    null,
  );
  const [step, setStep] = useState<number>(0);

  // Variable
  const { id = null } = useParams();
  const navigate = useNavigate();

  // Function
  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(router.workout);
    }
  };

  const convertFromNumbers = (time: string) => {
    if (time) {
      const [hours, minutes, seconds] = time.split(':');
      return Number(hours) * 60 + Number(minutes) * 60 + Number(seconds);
    }
  };

  const fetchWorkoutSingle = async (workoutId: string | null) => {
    try {
      const res = await GET_WORKOUT_BY_ID(workoutId);
      if (res?.status === 1 || res?.status === '1') {
        // eslint-disable-next-line
        const workoutSet = res?.data?.workout_sets.map((item: any) => {
          // eslint-disable-next-line
          item?.workout_durations?.map((ele: any) => {
            ele.duration = convertFromNumbers(ele?.duration);
          });
          return {
            ...item,
            ...item.exercise,
          };
        });
        // eslint-disable-next-line
        const workoutDetails: any = {
          cost_type: res?.data?.cost_type,
          description: res?.data?.description,
          duration: convertFromNumbers(res?.data?.duration),
          fitness_level: res?.data?.fitness_level,
          instruction: res?.data?.instruction,
          thumbnail: res?.data?.thumbnail,
          workout_cost: res?.data?.workout_cost,
          equipment: res?.data?.workout_equipments?.map(
            (item: { equipment: { id: string } }) => item.equipment.id,
          ),
          training_zones: res?.data?.workout_training_zones?.map(
            (item: { training_zone: { id: string } }) => item.training_zone.id,
          ),
          workout_name: res?.data?.workout_name,
          workout_visibility: res?.data?.workout_visibility,
        };
        setWorkOutDetails(workoutDetails);
        setWorkOutBuilder(workoutSet);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  // useEffect
  useEffect(() => {
    if (id) {
      fetchWorkoutSingle(id);
    }
  }, [id]);

  return {
    step,
    onNext,
    onBack,
    workOutBuilder,
    setWorkOutBuilder,
    workOutDetails,
  };
};

export default useAddEditHook;
