/* eslint-disable @typescript-eslint/no-explicit-any */
// Builtin
import { message } from 'antd';
import { useEffect, useState } from 'react';

// External
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import { router } from 'utils/constants';
import { PROGRAM } from '../api';

const useAddEditHook = () => {
  const [step, setStep] = useState<number>(0);
  const [programBuilder, setProgramBuilder] = useState<any>([]);
  const [programDetails, setProgramDetails] = useState<any | null>(null);
  const { id = null } = useParams();
  const navigate = useNavigate();
  // Function
  const onNext = () => {
    if (programBuilder?.length === 0) {
      message.error('Please Add At Least One Week');
    } else {
      setStep(step + 1);
    }
  };

  const onBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(router.program);
    }
  };

  const fetchProgramSingle = async (programId: string | null) => {
    try {
      const res = await PROGRAM.GET_BY_ID(programId);
      if (res?.status === 1 || res?.status === '1') {
        const programSet = res?.data?.program_weeks.map(
          (item: { week_no: any }) => {
            return {
              ...item,
              ...item?.week_no,
            };
          },
        );

        const programDetails = {
          cost_type: res?.data?.cost_type,
          description: res?.data?.description,
          program_duration: res?.data?.program_duration,
          fitness_level: res?.data?.fitness_level,
          instruction: res?.data?.instruction,
          thumbnail: res?.data?.thumbnail,
          program_cost: res?.data?.program_cost,
          equipment: res?.data?.program_equipments?.map(
            (item: { equipment: { id: string } }) => item.equipment.id,
          ),
          training_zones: res?.data?.program_training_zones?.map(
            (item: { training_zone: { id: string } }) => item.training_zone.id,
          ),
          program_name: res?.data?.program_name,
          program_visibility: res?.data?.program_visibility,
        };
        setProgramDetails(programDetails);
        setProgramBuilder(programSet);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong!'}`);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProgramSingle(id);
    }
  }, [id]);

  return {
    step,
    onNext,
    onBack,
    setProgramBuilder,
    programBuilder,
    programDetails,
  };
};

export default useAddEditHook;
