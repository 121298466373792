import React from 'react';

import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import Header from '../../components/header/Header';
import CustomInput from '../../components/input/CustomInput';
import { LOGIN } from './api';

const Login = () => {
  const navigate = useNavigate();
  const { setToken, setUserData } = useAuth();

  const handleLogin = async (value: { email: string; password: string }) => {
    try {
      const login = {
        email: value.email,
        password: value.password,
        strategy: 'local',
      };
      const res = await LOGIN(login);

      if (res.status === '1' || res.status === 1) {
        const token = res.data.accessToken;
        const user = res?.data?.user;
        localStorage.setItem('token', token);
        localStorage.setItem('user-data', JSON.stringify(user));
        setToken(token);
        setUserData(user);
        message.success('Login Successfully !');
        navigate('/');
      }
    } catch (err) {
      message.error('Invalid Email and Password');
    }
  };
  return (
    <>
      <Header />

      <div
        className="login_main_div"
        style={{ boxShadow: '0px 0px 8px 4px rgba(85, 85, 85, 0.06);' }}
      >
        <div className="login_wrapper">
          <Form onFinish={handleLogin} layout="vertical" requiredMark={false}>
            <div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Email is required !' },
                  {
                    message: 'Please input Valid Email!',
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line
                      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  },
                ]}
              >
                <CustomInput placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Password is required !' }]}
              >
                <Input.Password
                  type="password"
                  placeholder="Enter Password"
                  className="input-control w-100 line-height-22 font-size-16 text border-radius-8"
                  allowClear
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="ant-btn-lg w-100 btn border-radius-8 h-54 line-height-26 font-size-18 text-black"
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
