// Built-In
import React from 'react';

// External
import { Steps } from 'antd';

// Internal
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import useAddEditHook from '../hook/useAddEditHook';

const WorkOutAddEdit = () => {
  const {
    step,
    onNext,
    onBack,
    workOutBuilder,
    setWorkOutBuilder,
    workOutDetails,
  } = useAddEditHook();
  return (
    <>
      <div
        style={{
          maxWidth: '90%',
          width: '500px',
          margin: '30px auto',
        }}
      >
        <Steps current={step}>
          <Steps.Step title="Create Workout" className="cursor-pointer" />
          <Steps.Step title="Workout Details" className="cursor-pointer" />
        </Steps>
      </div>

      <div>
        {/* Step1 */}
        {step === 0 && (
          <Step1
            onNext={onNext}
            onBack={onBack}
            workOutBuilder={workOutBuilder}
            setWorkOutBuilder={setWorkOutBuilder}
          />
        )}
        {/* Step2 */}
        {step === 1 && (
          <Step2
            onBack={onBack}
            workOutBuilder={workOutBuilder}
            workOutDetails={workOutDetails}
          />
        )}
      </div>
    </>
  );
};

export default WorkOutAddEdit;
