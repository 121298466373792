/* eslint-disable @typescript-eslint/no-explicit-any */

import { methods, service } from 'service/service';

export const GET_USERS = (
  limit: string | number,
  skip: string | number = 0,
  search: string | null = null,
  sort: any | null = null,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (sort) {
    const name = Object.keys(sort)[0] || 'createdAt';
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: 'appusers', method: methods.GET, params: query });
};

export const GET_USER_BY_ID = (id: string | null) =>
  service({ url: `appusers/${id}`, method: methods.GET });

export const ADD_USER = (payload: any) =>
  service({ url: 'appusers', method: methods.POST, data: payload });

export const EDIT_USER = (id: string | null, payload: any) =>
  service({ url: `appusers/${id}`, method: methods.PATCH, data: payload });

export const DELETE_USER = (id: string) =>
  service({ url: `appusers/${id}`, method: methods.DELETE });

// export const USERS = {
//   GET: GET_USERS,
//   GET_BY_ID: GET_USER_BY_ID,
//   ADD: ADD_USER,
//   EDIT: EDIT_USER,
//   DELETE: DELETE_USER,
// };
