/* eslint-disable @typescript-eslint/no-explicit-any */
// Builtin
import React from 'react';

// External
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio, Select, Upload } from 'antd';

// Internal
import ButtonComponent from 'components/button/Button';
import CustomInput from 'components/input/CustomInput';
import InsideHeader from 'components/inside-header/InsideHeader';
import useStepTwoHook from 'features/program/hooks/useStepTwoHook';
import { FormValue, IProgramWeeks } from 'features/program/types';

interface Props {
  onBack: () => void;
  programBuilder: IProgramWeeks[];
  programDetails: FormValue;
}

const Step2 = ({ onBack, programBuilder, programDetails }: Props) => {
  const {
    form,
    value,
    onChange,
    uploadBtnPropsImage,
    trainingZone,
    equipment,
    onFinish,
    id,
  } = useStepTwoHook({ programBuilder, programDetails });

  return (
    <InsideHeader className="add-edit-form box-shadow-2">
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        name="control-hooks"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name="program_name"
          label="Program Name"
          className="font-size-18 line-height-26"
          rules={[{ required: true, message: 'Please fill Program Name' }]}
        >
          <CustomInput size="large" placeholder="Program Name" />
        </Form.Item>

        <Form.Item name="program_duration" label="Program Duration">
          <CustomInput
            type="number"
            size="large"
            placeholder="Program Duration"
          />
        </Form.Item>

        <Form.Item name="image" label="Thumbnail Image">
          <Upload
            accept=".png, .jpg, .jpeg"
            className="thumb-img"
            listType="picture"
            name="image"
            {...uploadBtnPropsImage}
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              className="btn-thumb"
            >
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="training_zones"
          label="Training Zone"
          className="font-size-18 line-height-26"
        >
          <Select
            showSearch
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Select Training Zones"
            className="font-size-18 line-height-26"
            options={trainingZone}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item
          name="equipment"
          label="Equipment"
          className="font-size-18 line-height-26"
        >
          <Select
            showSearch
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Select Equipment"
            className="font-size-18 line-height-26"
            options={equipment}
            optionLabelProp="label"
          />
        </Form.Item>

        <Form.Item name="fitness_level" label="Fitness Level">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="Beginner">Beginner</Radio>
            <Radio value="Intermediate">Intermediate</Radio>
            <Radio value="Advance">Advance</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="program_visibility" label="Program Visibility">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="public">Public</Radio>
            <Radio value="private">Private</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="cost_type" label="Program Cost Type">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="free">free</Radio>
            <Radio value="paid">Paid</Radio>
          </Radio.Group>
        </Form.Item>

        {value === 'paid' && (
          <Form.Item name="program_cost" label="Program Cost">
            <CustomInput
              type="number"
              size="large"
              placeholder="Program Cost"
            />
          </Form.Item>
        )}

        <Form.Item name="description" label="Program Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>

        <div className="form-btn-group d-flex justify-content-center">
          <ButtonComponent
            className="mr-2 width-150 btn-cancel btn-component"
            style={{ background: 'transparent', color: '#8C8673' }}
            onClick={onBack}
          >
            Back
          </ButtonComponent>
          <ButtonComponent
            htmlType="submit"
            type="primary"
            className="btn-save btn-all btn-component width-150"
          >
            {id ? 'Update' : 'Create'}
          </ButtonComponent>
        </div>
      </Form>
    </InsideHeader>
  );
};

export default Step2;
