// Build-In
import React from 'react';

// External
import { Route } from 'react-router-dom';

// Internal
import { router } from 'utils/constants';
import Program from '../Program';
import ProgramAddEdit from '../program-add-edit/ProgramAddEdit';

const AppRoutes = () => {
  return (
    <>
      <Route path={router.program} element={<Program />} />
      <Route path={router.createProgram} element={<ProgramAddEdit />} />
      <Route path={router.programEdit()} element={<ProgramAddEdit />} />
    </>
  );
};

export default AppRoutes;
