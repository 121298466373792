import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import ExerciseRouter from 'features/exercise/router';
import WorkOutRouter from 'features/workout/router';
import ProfileRoutes from 'features/profile/router';
import SectionRouter from 'features/section/router';
import ProgramRouter from 'features/program/router';
import LoginRouter from 'features/login/router';
import UserRouter from 'features/users/router';
import PrivateRoute from './PrivateRoutes';
import PublicRoute from './PublicRoutes';
import { router } from 'utils/constants';

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route element={<PublicRoute />}>{LoginRouter()}</Route>

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        {ExerciseRouter()}
        {WorkOutRouter()}
        {ProgramRouter()}
        {SectionRouter()}
        {UserRouter()}
        {ProfileRoutes()}
      </Route>

      {/* page not found */}
      <Route path="*" element={<Navigate to={router.exercise} />} />
    </Routes>
  );
};

export default AppRoutes;
