// Built-In
import React, { useState } from 'react';

// External
import { Layout } from 'antd';

// Internal
import Header from '../components/header/Header';
import Sidebar from '../components/Sidebar/Sidebar';

// Variable
const { Content } = Layout;

interface IProps {
  children: React.ReactNode;
}

const AdminLayout = ({ children }: IProps) => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Header />
        <Layout>
          <Sidebar setIsShowSidebar={setIsShowSidebar} />
          <Content
            style={{
              marginLeft: isShowSidebar ? '80px' : '190px',
              transition: 'all 0.4s',
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminLayout;
