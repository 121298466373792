/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Button, Select } from 'antd';

import CustomInput from '../../../components/input/CustomInput';
import TableImage from '../../../components/table-image/TableImage';
import ArrowUpIcon from '../icon/ArrowUpIcon';
import PlusIcon from '../icon/PlusIcon';
import ResetIcon from '../icon/ResetIcon';
import ArrowDownIcon from '../icon/ArrowDownIcon';
import DeleteIcon from '../icon/DeleteIcon';
import TrashIcon from '../icon/TrashIcon';

interface IBuilder {
  workOutBuilder: any;
  deleteWorkOutBuilder: (position: number) => void;
  setWorkOutBuilder: (value: any) => void;
}

const WorkOutBuilder = ({
  workOutBuilder,
  deleteWorkOutBuilder,
  setWorkOutBuilder,
}: IBuilder) => {
  const { Option } = Select;

  const handlePlus = (workoutIndex: string) => {
    const temp = [...workOutBuilder];
    const workOutIndex = workOutBuilder.findIndex(
      (item: any) => item.position_no === workoutIndex,
    );
    temp[workOutIndex].workout_durations.push({
      order: temp[workOutIndex].workout_durations.length + 1,
    });
    setWorkOutBuilder(temp);
  };

  const handleDelete = (
    // eslint-disable-next-line
    setsIndex: number,
    workoutIndex: string,
    setId: number,
  ) => {
    const temp = [...workOutBuilder];
    const workOutIndex = workOutBuilder.findIndex(
      (item: any) => item.position_no === workoutIndex,
    );

    if (temp[workOutIndex].workout_durations.length !== 1) {
      const filter = temp[workOutIndex].workout_durations.filter(
        (item: any) => item.order !== setId,
      );
      temp[workOutIndex].workout_durations = filter;
      setWorkOutBuilder(temp);
    }
  };

  const handleArrowUp = (index: number) => {
    const temp = [...workOutBuilder];
    const cutOut = temp.splice(index, 1)[0];
    temp.splice(index - 1, 0, cutOut);
    setWorkOutBuilder(temp);
  };

  const handleDown = (index: number) => {
    const temp = [...workOutBuilder];
    const cutOut = temp.splice(index, 1)[0];
    temp.splice(index + 1, 0, cutOut);
    setWorkOutBuilder(temp);
  };

  const handleChangeSets = (
    e: React.ChangeEvent<HTMLInputElement>,
    setsIndex: number,
    workoutIndex: number,
    type: string,
  ) => {
    const temp = [...workOutBuilder];
    const workOutIndex = temp.findIndex(
      (item: any) => item.position_no === workoutIndex,
    );
    if (type === 'rap') {
      temp[workOutIndex].workout_durations[setsIndex].rap_count =
        e.target.value;
      delete temp[workOutIndex].workout_durations[setsIndex].duration;
    }
    if (type === 'time') {
      temp[workOutIndex].workout_durations[setsIndex].duration = e.target.value;
      delete temp[workOutIndex].workout_durations[setsIndex].rap_count;
    }
    if (type === 'rest_time') {
      temp[workOutIndex].workout_durations[setsIndex].rest_time =
        e.target.value;
    }

    setWorkOutBuilder(temp);
  };

  const handleRest = (
    setsIndex: number,
    workoutExIndex: number,
    type: string,
  ) => {
    const temp = [...workOutBuilder];
    const workOutIndex = temp.findIndex(
      (item: any) => item.position_no === workoutExIndex,
    );
    if (type === 'time') {
      delete temp[workOutIndex].workout_durations[setsIndex].duration;
      delete temp[workOutIndex].workout_durations[setsIndex].rest_time;
    }
    if (type === 'rap') {
      delete temp[workOutIndex].workout_durations[setsIndex].rap_count;
      delete temp[workOutIndex].workout_durations[setsIndex].rest_time;
    }
    setWorkOutBuilder(temp);
  };

  const handleChangeType = (
    e: React.ChangeEvent<HTMLInputElement>,
    setsIndex: number,
    workoutId: number,
  ) => {
    const temp = [...workOutBuilder];
    const workOutIndex = temp.findIndex(
      (item: any) => item.position_no === workoutId,
    );
    temp[workOutIndex].workout_durations[setsIndex].type = e;
    setWorkOutBuilder(temp);
  };

  return (
    <>
      {workOutBuilder.map((item: any, i: number) => {
        return (
          <div className="mb-3 border-grey border-radius-8" key={i}>
            <div className="p-3 d-flex justify-content-between">
              <div className="d-flex g-3 align-items-center">
                <TableImage image={item.thumbnail?.media} />
                <p className="mb-0 font-size-18 line-height-26 vid-title width-250">
                  {item.exercise_name}
                </p>
              </div>
              <div className="d-flex g-16 align-items-center">
                <Button
                  size="large"
                  onClick={() => handleArrowUp(i)}
                  disabled={i === 0}
                >
                  <ArrowUpIcon />
                </Button>
                <Button
                  size="large"
                  onClick={() => handleDown(i)}
                  disabled={i === workOutBuilder.length - 1}
                >
                  <ArrowDownIcon />
                </Button>
                <Button
                  size="large"
                  onClick={() => deleteWorkOutBuilder(item.position_no)}
                >
                  <TrashIcon />
                </Button>
              </div>
            </div>

            {item?.workout_durations?.map((set: any, j: number) => {
              return (
                <div
                  className="d-flex p-3 g-16 pt-0 align-items-center"
                  key={set.id}
                >
                  <p className="mb-0 font-size-16 line-height-22">
                    Set {j + 1}:
                  </p>
                  <Select
                    className="font-size-18 line-height-26 width-150 ml-0"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeType(e, j, item.position_no)
                    }
                    value={set.type}
                  >
                    <Option value="time">Time</Option>
                    <Option value="rap">Rep</Option>
                  </Select>
                  {set.type === 'time' && (
                    <CustomInput
                      type="number"
                      step="1"
                      placeholder="Enter Set Duration (in Seconds)"
                      style={{ maxWidth: '29%' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeSets(e, j, item.position_no, set.type)
                      }
                      value={set?.duration}
                    />
                  )}
                  {set.type === 'rap' && (
                    <CustomInput
                      type="number"
                      placeholder="Enter Rap Count"
                      style={{ maxWidth: '29%' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeSets(e, j, item.position_no, set.type)
                      }
                      value={set?.rap_count}
                    />
                  )}
                  {(set.type === 'time' || set.type === 'rap') && (
                    <>
                      <CustomInput
                        type="number"
                        placeholder="Rest Time After Set (in Seconds)"
                        style={{ maxWidth: '29%' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeSets(e, j, item.position_no, 'rest_time')
                        }
                        value={set?.rest_time}
                      />
                      <div className="d-flex g-16 align-items-center">
                        <PlusIcon
                          className="cursor-pointer"
                          onClick={() => handlePlus(item.position_no)}
                        />
                        <ResetIcon
                          className="cursor-pointer"
                          onClick={() =>
                            handleRest(j, item.position_no, set.type)
                          }
                        />
                        <DeleteIcon
                          className="cursor-pointer"
                          onClick={() =>
                            handleDelete(j, item.position_no, set.order)
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default WorkOutBuilder;
