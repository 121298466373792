/* eslint-disable @typescript-eslint/no-explicit-any */
import { methods, service } from 'service/service';

interface ISection {
  $limit: number;
  search?: string;
}

interface ISectionItem {
  $limit: number;
  $skip: number;
  sectionId: string | null;
  search?: string;
}

export const GET_SECTION = (search?: string | null) => {
  const query: ISection = { $limit: -1 };
  if (search) {
    query.search = search;
  }
  return service({ url: 'sections', method: methods.GET, params: query });
};

export const CREATE_MULTI_SECTION = (payload: any) =>
  service({ url: 'section-items', method: methods.POST, data: payload });

export const DELETE_SECTION_ITEM = (id: string | null) => {
  return service({ url: `section-items/${id}`, method: methods.DELETE });
};

export const GET_SECTION_ITEM = (
  id: string | null,
  limit: number,
  skip: number,
  search: string | null,
) => {
  const query: ISectionItem = {
    $limit: limit,
    $skip: skip,
    sectionId: id,
  };
  if (search) {
    query.search = search;
  }
  return service({ url: 'section-items', method: methods.GET, params: query });
};

export const UPDATE_SECTION_ITEM = (id: string, payload: any) => {
  return service({
    url: `section-items/${id}`,
    method: methods.PATCH,
    data: payload,
  });
};

export const GET_EXERCISE = (
  limit: number,
  skip: number,
  search: string | null,
  status: string | null,
  trainingZoneId: string | null,
  equipmentId: string | null,
  exercise_category: string | null,
  sectionId: string | null,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
    '$sort[createdAt]': -1,
  };
  if (search) {
    query.search = search;
  }
  if (status) {
    query.status = status;
  }
  if (trainingZoneId) {
    query.trainingZoneId = trainingZoneId;
  }
  if (equipmentId) {
    query.equipmentId = equipmentId;
  }
  if (exercise_category) {
    query.exercise_category = exercise_category;
  }
  if (sectionId) {
    query.sectionId = sectionId;
  }
  return service({ url: 'exercise', method: methods.GET, params: query });
};

export const GET_WORKOUTS = (
  limit: number,
  skip: number,
  fitnessLevel?: string | null,
  trainingZoneId?: string | null,
  equipmentId?: string | null,
  search?: string | null,
  sort?: any,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (fitnessLevel) {
    query.fitness_level = fitnessLevel;
  }
  if (trainingZoneId) {
    query.trainingZoneId = trainingZoneId;
  }
  if (equipmentId) {
    query.equipmentId = equipmentId;
  }
  if (sort) {
    const name = Object.keys(sort)[0];
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: 'workout', method: methods.GET, params: query });
};

export const GET_PROGRAMS = (
  limit: number,
  skip: number,
  fitnessLevel?: string | null,
  trainingZoneId?: string | null,
  equipmentId?: string | null,
  search?: string | null,
  sort?: any,
) => {
  const query: any = {
    $limit: limit,
    $skip: skip,
  };
  if (search) {
    query.search = search;
  }
  if (fitnessLevel) {
    query.fitness_level = fitnessLevel;
  }
  if (trainingZoneId) {
    query.trainingZoneId = trainingZoneId;
  }
  if (equipmentId) {
    query.equipmentId = equipmentId;
  }
  if (sort) {
    const name = Object.keys(sort)[0];
    const value = Object.values(sort)[0];
    query[`$sort[${name}]`] = value;
  } else {
    query['$sort[createdAt]'] = -1;
  }
  return service({ url: 'program', method: methods.GET, params: query });
};
