/* eslint-disable @typescript-eslint/no-explicit-any */
// Built - In
import React, { useEffect, useState } from 'react';

// External
import { message } from 'antd';

// Internal
import ButtonComponent from 'components/button/Button';
import { IDropDown, IExercise, IResponse } from 'types';
import { ColumnsType } from 'antd/lib/table';
import { GET_EXERCISE, GET_SECTION } from 'features/workout/api';
import { GET_EQUIPMENT, GET_TRAINING_ZONE } from 'service/api';
import TableImage from 'components/table-image/TableImage';

interface IProps {
  workOutBuilder: any;
  setWorkOutBuilder: (value: any) => void;
}

const useStepOneHook = ({ workOutBuilder, setWorkOutBuilder }: IProps) => {
  // useState
  const [exercise, setExercise] = useState<IResponse<IExercise>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);

  const [trainingZone, setTrainingZone] = useState<IDropDown[]>([]);
  const [equipment, setEquipment] = useState<IDropDown[]>([]);
  const [section, setSection] = useState<IDropDown[]>([]);

  const [trainingZoneValue, setTrainingZoneValue] = useState<string | null>(
    null,
  );
  const [equipmentValue, setEquipmentValue] = useState<string | null>(null);
  const [categoryValue, setCategoryValue] = useState<string | null>(null);
  const [sectionValue, setSectionValue] = useState<string | null>(null);
  const [statusValue, setStatusValue] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string | null>(null);
  const [tempSearch, setTempSearch] = useState<string | null>(null);

  // Variable
  const columns: ColumnsType<IExercise> = [
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 130,
      render: (thumbnail) => <TableImage image={thumbnail?.media} />,
    },
    {
      title: 'Name',
      dataIndex: 'exercise_name',
      key: 'exercise_name',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_: any, record) => {
        return (
          <div className="g-3">
            <ButtonComponent
              className="border-primary"
              style={{ padding: '12px 16px' }}
              onClick={() => {
                const temp = [...workOutBuilder];
                // const ind = 1;
                temp.push({
                  ...record,
                  workout_durations: [{ order: 1 }],
                  position_no: temp.length,
                });

                setWorkOutBuilder(temp);
                message.success('Exercise Added Successfully');
              }}
            >
              + Add
            </ButtonComponent>
          </div>
        );
      },
    },
  ];

  // Function
  const fetchExercise = async (
    searchValue: string | null,
    pageP: number,
    pageSizeP: number,
    status: string | null,
    trainingZoneId: string | null,
    equipmentId: string | null,
    exercise_category: string | null,
    sectionId: string | null,
  ) => {
    setExercise(undefined);
    setIsLoading(true);
    try {
      const res = await GET_EXERCISE(
        pageSizeP,
        (pageP - 1) * pageSizeP,
        searchValue,
        status,
        trainingZoneId,
        equipmentId,
        exercise_category,
        sectionId,
      );
      if (res.status === 1 || res.status === '1') {
        setExercise(res);
      }
    } catch (err) {
      setIsLoading(false);
      message.error(`${err || 'Something went wrong!'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrainingZone = async () => {
    setTrainingZone([]);
    try {
      const res = await GET_TRAINING_ZONE(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTrainingZone(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const fetchEquipment = async () => {
    setEquipment([]);
    try {
      const res = await GET_EQUIPMENT(-1, 0, '', '');
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setEquipment(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const fetchSection = async () => {
    setSection([]);
    try {
      const res = await GET_SECTION();
      if (res?.status === 1 || res?.status === '1') {
        const temp = res?.data?.map((item: { name: string; id: string }) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setSection(temp);
      }
    } catch (err) {
      message.error(`${err || 'Something went wrong!'}`);
    }
  };

  const deleteWorkOutBuilder = (position: number) => {
    const temp = workOutBuilder.filter(
      (item: { position_no: number }) => item.position_no !== position,
    );
    setWorkOutBuilder(temp);
  };

  const clearAllFilter = () => {
    setTrainingZoneValue(null);
    setEquipmentValue(null);
    setCategoryValue(null);
    setSectionValue(null);
    setStatusValue(null);
  };

  const handleChangeOfSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setPage(1);
    setSearch(tempSearch);
  };

  const onChangePage = (pageT: number, pageSizeT: number) => {
    setPage(pageT);
    setPageSize(pageSizeT);
  };

  // useEffect
  useEffect(() => {
    fetchExercise(
      search,
      page,
      pageSize,
      statusValue,
      trainingZoneValue,
      equipmentValue,
      categoryValue,
      sectionValue,
    );
  }, [
    categoryValue,
    equipmentValue,
    page,
    pageSize,
    search,
    sectionValue,
    statusValue,
    trainingZoneValue,
  ]);

  useEffect(() => {
    fetchTrainingZone();
    fetchEquipment();
    fetchSection();
  }, []);

  return {
    handleChangeOfSearch,
    tempSearch,
    handleSearch,
    setIsShowFilter,
    isShowFilter,
    trainingZone,
    setTrainingZoneValue,
    trainingZoneValue,
    equipment,
    equipmentValue,
    setEquipmentValue,
    setCategoryValue,
    categoryValue,
    section,
    setSectionValue,
    sectionValue,
    setStatusValue,
    statusValue,
    clearAllFilter,
    columns,
    exercise,
    isLoading,
    onChangePage,
    page,
    deleteWorkOutBuilder,
  };
};

export default useStepOneHook;
