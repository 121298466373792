import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import AuthContextProvider from './context/AuthContext';

import App from './App';
import 'antd-css-utilities/utility.min.css';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.variable.min.css';
import './assets/scss/style.scss';
import reportWebVitals from './reportWebVitals';

ConfigProvider.config({
  theme: {
    primaryColor: '#FBC90B',
    errorColor: 'red',
    successColor: '#359766',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HashRouter>
    <React.StrictMode>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </React.StrictMode>
  </HashRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
