export const SUCCESS = 200;
export const CREATE_RECORD = 201;
export const BAD_REQUEST = 400;
export const INTERNAL_SERVER_ERROR = 500;
export const UNAUTHORIZED_REQUEST = 401;
export const NOT_FOUND = 404;
export const VALIDATION_ERROR = 422;

export const router = {
  // login
  login: '/login',

  // section
  section: '/sections',
  sectionItemAdd: '/sections/section-item/add',
  sectionSeeAll: (id?: string) => {
    if (id) {
      return `/sections/section-item/all/${id}`;
    }
    return '/sections/section-item/all/:id';
  },

  // Exercise
  exercise: 'exercise',
  exerciseAdd: '/exercise/add',
  exerciseEdit: (id?: string) => {
    if (id) {
      return `/exercise/edit/${id}`;
    }
    return '/exercise/edit/:id';
  },

  // Workout
  workout: '/workout',
  workoutAdd: '/workout/add',
  workoutEdit: (id?: string) => {
    if (id) {
      return `/workout/edit/${id}`;
    }
    return '/workout/edit/:id';
  },

  // users
  user: 'users',
  userAdd: '/users/add',
  userEdit: (id?: string) => {
    if (id) {
      return `/users/edit/${id}`;
    }
    return '/users/edit/:id';
  },

  // Program
  program: '/program',
  createProgram: '/program/create',
  programEdit: (id?: string) => {
    if (id) {
      return `/program/edit/${id}`;
    }
    return '/program/edit/:id';
  },

  // Profile
  profile: 'profile',
  changePassword: 'change-password',
};
