import React from 'react';

const RightIcon = () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.61111L5.92308 12.5L17 1.5"
        stroke="#359766"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightIcon;
