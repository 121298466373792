/* eslint-disable @typescript-eslint/no-explicit-any */
// Built-In
import React, { useState } from 'react';

// External
import { Card, Col, message, Row, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon, InfoIcon } from '../../../icon/CommonIcon';

import WorkOutInfoDrawer from './WorkOutInfoDrawer';
import CustomModel from '../../../components/model/CustomModel';
import ButtonComponent from '../../../components/button/Button';
import { router } from '../../../utils/constants';
import EditItemModel from './EditItemModel';
import ExerciseInfoDrawer from 'features/section/components/ExerciseInfoDrawer';
import { DELETE_SECTION_ITEM, UPDATE_SECTION_ITEM } from 'features/section/api';
import { ProgramInfoDrawer } from './ProgramInfoDrawer';

// Interface
interface IProps {
  section: any;
  fetchSection: (value: string | null) => void;
  search: string | null;
}

const SectionCard = ({ section, fetchSection, search }: IProps) => {
  // useState
  const [isShowDeleteModel, setIsShowDeleteModel] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string | null>(null);

  const [infoSliderVisible, setInfoSliderVisible] = useState<boolean>(false);
  const [singleRecordInfo, setSingleRecordInfo] = useState<any>();

  const [isShowEditModel, setIsShowEditModel] = useState<boolean>(false);
  const [sectionValue, setSectionValue] = useState<string | null>(null);

  // Variable
  const navigate = useNavigate();

  // Function
  const handleDelete = (id: string) => {
    setIsShowDeleteModel(true);
    setRecordId(id);
  };

  const handleCancelDeleteModal = () => {
    setRecordId(null);
    setIsShowDeleteModel(false);
  };

  const deleteSectionItem = async () => {
    try {
      const res = await DELETE_SECTION_ITEM(recordId);
      if (res?.status === 1 || res?.status === '1') {
        message.success(res.message);
        handleCancelDeleteModal();
        fetchSection(search);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  const handleShowInfo = (item: any) => {
    setInfoSliderVisible(true);
    setSingleRecordInfo(item);
  };

  const handleShowEdit = (item: any) => {
    setIsShowEditModel(true);
    setSingleRecordInfo(item);
  };

  const handleCancelEditModel = () => {
    setIsShowEditModel(false);
    setSingleRecordInfo(null);
    setSectionValue(null);
  };

  const handleSave = async () => {
    const payload = {
      sectionId: sectionValue,
    };
    try {
      const res = await UPDATE_SECTION_ITEM(singleRecordInfo?.id, payload);
      if (res?.status === 1 || res?.status === '1') {
        handleCancelEditModel();
        fetchSection(search);
        message.success(res?.message);
      }
    } catch (error) {
      message.error(`${error || 'Something went wrong !'}`);
    }
  };

  return (
    <>
      {/* See all header */}
      <div className="mt-3 d-flex justify-content-between">
        <p className="font-size-24 line-height-34 font-weight-600 mb-0">
          {section?.name}
        </p>
        <ButtonComponent
          className="mr-2 border-primary btn-cancel btn-component"
          onClick={() => navigate(router.sectionSeeAll(section?.id))}
        >
          See All
        </ButtonComponent>
      </div>

      {/* Card */}
      <Row className="mt-3" gutter={[30, 30]}>
        {section?.section_items?.map((item: any) => {
          return (
            <Col xxl={4} xl={4} lg={8} md={12} sm={12} key={item.id}>
              <Card
                className="card-sections"
                cover={
                  <img
                    loading="lazy"
                    crossOrigin="anonymous"
                    src={
                      item?.exercise?.thumbnail?.media ||
                      item?.workout?.thumbnail?.media ||
                      item?.program?.thumbnail?.media
                    }
                    alt=""
                    className="card-section-img"
                  />
                }
              >
                <p className="mt-16 font-size-18 line-height-26 vid-title">
                  {item?.exercise?.exercise_name ||
                    item?.workout?.workout_name ||
                    item?.program?.program_name}
                </p>

                <div className="mt-16 d-flex g-3 font-size-20 justify-content-center">
                  <Tooltip title="Edit">
                    <EditIcon
                      className="cursor-pointer"
                      onClick={() => handleShowEdit(item)}
                    />
                  </Tooltip>
                  <Tooltip title="Info">
                    <InfoIcon
                      className="cursor-pointer"
                      onClick={() => handleShowInfo(item)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      className="cursor-pointer"
                      onClick={() => handleDelete(item.id)}
                    />
                  </Tooltip>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Delete Modal */}
      {isShowDeleteModel && (
        <CustomModel
          open={isShowDeleteModel}
          handleOk={deleteSectionItem}
          handleCancel={handleCancelDeleteModal}
          title="Delete"
          description="Are you sure you want to delete this?"
          okBtnText="Delete"
        />
      )}

      {/* Info Drawer of Exercise */}
      {infoSliderVisible && singleRecordInfo.item_type === 'exercise' && (
        <ExerciseInfoDrawer
          infoSliderVisible={infoSliderVisible}
          setInfoSliderVisible={setInfoSliderVisible}
          data={singleRecordInfo}
        />
      )}

      {/* Info Drawer of Workout */}
      {infoSliderVisible && singleRecordInfo.item_type === 'workout' && (
        <WorkOutInfoDrawer
          infoSliderVisible={infoSliderVisible}
          setInfoSliderVisible={setInfoSliderVisible}
          data={singleRecordInfo}
        />
      )}

      {/* Info Drawer Of Program */}
      {infoSliderVisible && singleRecordInfo.item_type === 'program' && (
        <ProgramInfoDrawer
          infoSliderVisible={infoSliderVisible}
          setInfoSliderVisible={setInfoSliderVisible}
          data={singleRecordInfo}
        />
      )}

      {/* Edit Drawer  */}
      {isShowEditModel && (
        <EditItemModel
          handleOk={handleSave}
          open={isShowEditModel}
          handleCancel={handleCancelEditModel}
          data={singleRecordInfo}
          sectionValue={sectionValue}
          setSectionValue={setSectionValue}
        />
      )}
    </>
  );
};

export default SectionCard;
